import "./AddGuests.scss";
import distance from "../../imgs/distance.png";

function TooFar({ business, appearance }) {
  var buttonStyle = {
    backgroundColor: appearance.highlightBackgroundColor,
    color: appearance.highlightFontColor,
    width: "200px",
    fontFamily: appearance.fontFamily
  };
  return (
    <div className="checkin-error-message">
      You are too far from {business.name} to use self check-in. Please move closer and tap reload.
      <div style={{ padding: "40px" }}>
        <img src={distance} alt="Too Far Away Image" />
      </div>
      <button className="btn btn-default" style={buttonStyle} onClick={() => location.reload()}>
        Reload
      </button>
    </div>
  );
}

export default TooFar;
