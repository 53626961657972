import React, { useEffect, useState } from "react";
import { calculateLogoStyle } from "../services/helpers.service";
import { useSelector } from "react-redux";

function Header(props) {
  const [showHeader, setShowHeader] = useState(true);
  const { display } = useSelector(state => {
    return state;
  });

  useEffect(() => {
    if (display && display.widgetMode !== undefined) {
      setShowHeader(!display.widgetMode);
    } else {
      setShowHeader(true);
    }
  }, [display]);

  return (
    <div className="header-container">
      {showHeader && (
        <>
          {props.appearance.logoUrl ? (
            <div className="header-logo" style={{ paddingTop: props.appearance.logoMarginTop || "10px" }}>
              <img
                className="header-logo-image"
                style={calculateLogoStyle(props.appearance)}
                src={props.appearance.logoUrl}
                alt="header logo"
              />
            </div>
          ) : (
            <div className="header">
              <h2
                style={{
                  fontFamily: props.appearance.fontFamily,
                  color: props.fontColor
                }}
              >
                {props.business?.name || props.accountName}
              </h2>
            </div>
          )}
        </>
      )}
    </div>
  );
}

export default Header;
