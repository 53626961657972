import React, { Component } from "react";
import { Modal, Button } from "react-bootstrap";
import { BeatLoader } from "react-spinners";

class CancelModal extends Component {
  render() {
    return (
      <Modal className="static-modal" show={this.props.show}>
        <Modal.Header>
          <Modal.Title>Are you sure?</Modal.Title>
        </Modal.Header>

        <Modal.Body>Cancel your reservation?</Modal.Body>

        <Modal.Footer>
          <Button onClick={this.props.onYes} disabled={this.props.isYesLoading}>
            {this.props.isYesLoading ? <BeatLoader margin={2.5} size={10} color={"#447df7"} /> : "Yes"}
          </Button>
          <Button bsStyle="primary" onClick={this.props.onNo}>
            No
          </Button>
        </Modal.Footer>
      </Modal>
    );
  }
}

export default CancelModal;
