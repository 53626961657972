import React, { useEffect, useState } from "react";
import { Form, Row, Col, HelpBlock, FormGroup, ControlLabel, Button } from "react-bootstrap";
import { useForm } from "react-hook-form";
import "./AddGuests.scss";
import { verifyPendingVisitCode } from "../../services/checkin.service";
import { useNavigate } from "react-router-dom";
import BeatLoader from "react-spinners/BeatLoader";
import Loading from "components/Loading";

function FinishRegistration(props) {
  const { register, handleSubmit, errors } = useForm();
  const emptyForm = {
    code: null
  };
  const [form] = useState({ ...emptyForm });
  const [loading, setLoading] = useState(false);
  const [isFullScreenLoading, setIsFullScreenLoading] = useState(false);
  const [message, setMessage] = useState(null);
  const navigate = useNavigate();

  function verifyCode(data) {
    if (loading) {
      return;
    }
    setLoading(true);
    verifyPendingVisitCode({
      code: data.code,
      id: props.pendingVisitId
    })
      .then(response => response.json())
      .then(response => {
        setLoading(false);
        document.body.scrollTop = 0; // For Safari
        document.documentElement.scrollTop = 0;
        if (response.visitId) {
          localStorage.setItem(props.businessId + "previousConfirmation", response.visitId);
        }
        navigate(`/checkin-successful/${props.businessId}?hash=${response.id}`);
      })
      .catch(error => {
        setLoading(false);
        console.log("verifyPendingVisitCode error: ", error);
        setMessage("You entered invalid auth code. Please try again.");
      });
  }

  useEffect(() => {
    if (props.code) {
      setIsFullScreenLoading(true);
      verifyCode({ code: props.code });
    }
  }, [props.code]);

  var buttonStyle = { fontFamily: props.appearance.fontFamily };
  if (isFullScreenLoading) {
    return <Loading />;
  }
  return (
    <div className="add-guest-wrapper">
      <div className="add-guest">
        <Form noValidate onSubmit={handleSubmit(verifyCode)}>
          <Row>
            <Col className="add-guest__title" md={12}>
              We sent you a text message. Please enter the 4 digit one time passcode and we'll add you to the waitlist.
            </Col>
          </Row>
          <Row>
            <Col md={12}>
              <FormGroup className="add-guest__form-group" validationState={(!!errors.code && "error") || null}>
                <ControlLabel>One time passcode</ControlLabel>
                <input
                  name="code"
                  className="form-control"
                  type="number"
                  autoFocus
                  inputMode="tel"
                  defaultValue={form.code}
                  ref={register({ required: true, minLength: 4, maxLength: 4 })}
                />
                {errors.code && errors.code.type === "required" && <HelpBlock>Please enter the passcode</HelpBlock>}
                {errors.code && (errors.code.type === "minLength" || errors.code.type === "maxLength") && (
                  <HelpBlock>Passcode should have 4 digits</HelpBlock>
                )}
              </FormGroup>
            </Col>
          </Row>
          <Row>
            <Col md={12}>
              <div className="add-guest__actions">
                {loading ? (
                  <BeatLoader margin={2.5} size={10} color={"#447df7"} />
                ) : (
                  <Button style={buttonStyle} type="submit">
                    Add me to the list
                  </Button>
                )}
              </div>
            </Col>
          </Row>
          {message ? (
            <Row>
              <Col md={12}>
                <div className="add-guest__message">{message}</div>
              </Col>
            </Row>
          ) : null}
        </Form>
      </div>
    </div>
  );
}

export default FinishRegistration;
