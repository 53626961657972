import React, { useState, useEffect } from "react";
import Loading from "../Loading";
import { defaultAppearance, DEFAUL_TIMEZONE, DEFAULT_HOURS } from "../../constants";
import { useNavigate, useParams } from "react-router-dom";
import LayoutWrapper from "../shared/LayoutWrapper/LayoutWrapper";
import AddReservationForm from "./AddReservationForm";
import ReservationSuccessful from "./ReservationSuccessful";
import ViewWaitlist from "./ViewWaitlist";
import moment from "moment-timezone";
import { bookReservation, putToWaitlist } from "../../services/reservation.service";
import { routes } from "../../api/routes";
import { logError } from "../../services/log.service";
import { useErrorBoundary } from "use-error-boundary";
import turtle from "../../imgs/turtle.png";
import EmailNoticeModal from "components/EmailNoticeModal";
import { Settings } from "luxon";

function AddReservation(props) {
  const { shortId } = useParams();
  const [loading, setLoading] = useState(false);
  const [showNotice, setShownotice] = useState(false);
  const [email, setEmail] = useState();
  const [reservationData, setReservationData] = useState(false);
  const [business, setBusiness] = useState(null);
  const [appearance, setAppearance] = useState(null);
  const [plan, setPlan] = useState(null);
  const [loadingError, setLoadingError] = useState(false);
  const navigate = useNavigate();
  const { ErrorBoundary, didCatch, error } = useErrorBoundary();

  useEffect(() => {
    setLoading(true);
    getReservationData(shortId).then(() => setLoading(false));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (business?.settings?.timezone) {
      Settings.defaultZone = business.settings.timezone;
    }
  }, [business]);

  function getReservationData(shortId) {
    return fetch(`${routes.getReservationData}?id=${shortId}`)
      .then(response => response.json())
      .then(json => {
        if (json.error) {
          setLoadingError(true);
          setLoading(false);
          logError("Error Fetching reservationData", json.error, { shortId, json });
        }
        if (!props.type && json.reservationTime) {
          navigate(`success`);
        }
        if (!json.checkedIn && props.type !== "success") {
          setEmail(json.email);
          setShownotice(json.email ? true : false);
        }
        setReservationData(json);
        fetchAppearance(json.businessId);
      })
      .catch(err => {
        setLoadingError(true);
        logError("getReservationData rejected", err, { shortId });
        console.log("err: ", err);
      });
  }

  function bookNow({ selectedDate, selectedResource, selectedTime, phone, make, model, color }) {
    //selectedResource, selectedDay, selectedTime
    setLoading(true);
    if ((!selectedResource || selectedResource === "") && reservationData.resources[0]) {
      selectedResource = reservationData.resources[0].name;
    }
    const date = moment.tz(`${selectedDate} ${selectedTime}`, "MM/DD/YYYY H:mm", getTimezone());
    bookReservation(shortId, { date, resource: selectedResource, phone, make, model, color }).then(result => {
      getReservationData(shortId).then(() => {
        navigate(`/r/${shortId}/success`);
      });
    });
  }

  function getTimezone() {
    return reservationData.timezone || DEFAUL_TIMEZONE;
  }

  function addToWaitlist(formData) {
    setLoading(true);
    putToWaitlist(shortId, formData)
      .then(response => response.json())
      .then(result => {
        setLoading(false);
        navigate(`/r/${shortId}/waitlist?hashId=${result.id}`);
      })
      .catch(() => setLoading(false));
  }

  function fetchAppearance(businessId) {
    fetch(`${routes.checkInData}?id=${businessId}`)
      .then(response => response.json())
      .then(json => {
        setAppearance({ ...defaultAppearance, ...(json.appearance || {}) });
        setLoading(false);
        setBusiness(json.businesses[0]);
        setPlan(json.plan);
        setLoadingError(false);
      })
      .catch(err => {
        console.log("err: ", err);
        // set default appearance
        setAppearance(defaultAppearance);
        setBusiness("NA");
        setLoading(false);
        setLoadingError(true);
      });
  }
  const goToDomain = () => {
    window.location.href = generateUrl(appearance.domain);
  };

  const generateUrl = domain => {
    if (!domain) {
      return "";
    }
    if (domain.indexOf("www") !== -1) {
      return `https://${domain}`;
    } else {
      return `https://www.${domain}`;
    }
  };

  const onCheckIfClosed = () => {
    const hours = onGetWorkingHours();
    return hours.closed;
  };

  const onGetWorkingHours = () => {
    const workingHours = business?.settings?.waitlist?.hours || DEFAULT_HOURS;
    const dayOfWeek = moment(moment.tz(business?.timezone).toDate(), "MM/DD/YYYY").day();
    return workingHours[dayOfWeek];
  };

  const onCheckIfLinkIsNotAvailable = () => {
    return plan === "free" || (!reservationData.selfCheckIn && !reservationData.selfBooking);
  };

  const onCheckIfClosedShouldBeShown = () => {
    return (
      reservationData.selfCheckIn &&
      !reservationData.selfBooking &&
      (onCheckIfClosed() || !reservationData.waitlistIsOpen)
    );
  };

  const handleOk = event => {
    setShownotice(false);
  };

  const RenderContent = () => {
    if (onCheckIfLinkIsNotAvailable()) {
      return <div className="checkin-error-message">Sorry. This link is not available.</div>;
    } else {
      return (
        <LayoutWrapper business={business} appearance={appearance}>
          <EmailNoticeModal show={showNotice} email={email} handleOk={handleOk} />
          {onCheckIfClosedShouldBeShown() ? (
            <div className="checkin-error-message">
              We're sorry we missed you. Please check back during normal business hours.
              {appearance.domain && (
                <p className="checkin-error-message">
                  Please visit {/* eslint-disable-next-line jsx-a11y/anchor-is-valid */}
                  <a onClick={goToDomain} style={{ color: appearance.footerBackgroundColor || "blue" }}>
                    <strong>{appearance.domain}</strong>
                  </a>
                </p>
              )}
            </div>
          ) : (
            <div className="add-reservation" style={{ color: appearance.fontColor }}>
              {!props.type || props.type === "edit" ? (
                loadingError || reservationData.checkedIn || reservationData.expired ? (
                  <div className="add-reservation__error">
                    Sorry, this link is no longer available.
                    {appearance.domain && (
                      <p className="checkin-error-message">
                        Please visit {/* eslint-disable-next-line jsx-a11y/anchor-is-valid */}
                        <a onClick={goToDomain} style={{ color: appearance.footerBackgroundColor || "blue" }}>
                          <strong>{appearance.domain}</strong>
                        </a>
                      </p>
                    )}
                  </div>
                ) : (
                  <AddReservationForm
                    timezone={getTimezone()}
                    bookNow={bookNow}
                    closed={onCheckIfClosed()}
                    addToWaitlist={addToWaitlist}
                    shortId={shortId}
                    reservationData={reservationData}
                    appearance={appearance}
                    resources={reservationData.resources}
                  />
                )
              ) : props.type === "success" ? (
                <ReservationSuccessful
                  timezone={getTimezone()}
                  shortId={shortId}
                  reservationData={reservationData}
                  appearance={appearance}
                />
              ) : props.type === "waitlist" ? (
                <ViewWaitlist shortId={shortId} appearance={appearance} />
              ) : null}
            </div>
          )}
        </LayoutWrapper>
      );
    }
  };

  return loading || !appearance ? (
    <div>
      <Loading />
    </div>
  ) : (
    <>
      {didCatch || error ? (
        <div className="turtle-error">
          <div>
            <img src={turtle} alt="turtle" />
          </div>
          <div class="turtle-error__text">Oops!</div>
          <div class="turtle-error__sub-text">There's a problem.</div>
          <div class="turtle-error__sub-text"> We're working on make it right!</div>
        </div>
      ) : (
        <ErrorBoundary>
          <RenderContent />
        </ErrorBoundary>
      )}
    </>
  );
}

export default AddReservation;
