import React from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCalendarAlt, faConciergeBell, faMapMarkerAlt, faUser, faAt } from "@fortawesome/free-solid-svg-icons";
import "./AddReservation.scss";
import moment from "moment";

function ReservationDetails(props) {
  const { reservationData, timezone } = props;
  return (
    <div className="reservation-details">
      <div
        className="reservation-details__title reservation-details__row"
        style={{ borderColor: props.appearance.fontColor }}
      >
        Details
      </div>
      {reservationData?.selectedResource ? (
        <div className="reservation-details__row">
          <div className="reservation-details__icon">
            <FontAwesomeIcon
              size="2x"
              style={{ color: props.appearance.highlightBackgroundColor || "green" }}
              icon={faConciergeBell}
            ></FontAwesomeIcon>
          </div>
          <div className="reservation-details__text">{reservationData?.selectedResource}</div>
        </div>
      ) : null}
      {props.type !== "registration" ? (
        <div className="reservation-details__row">
          <div className="reservation-details__icon">
            <FontAwesomeIcon
              size="2x"
              style={{ color: props.appearance.highlightBackgroundColor || "green" }}
              icon={faCalendarAlt}
            ></FontAwesomeIcon>
          </div>
          <div className="reservation-details__text">
            {moment(reservationData.reservationTime)
              .tz(timezone)
              .format("MM/DD/YYYY h:mm a")}{" "}
            -{" "}
            {moment(reservationData.endTime)
              .tz(timezone)
              .format("h:mm a")}
          </div>
        </div>
      ) : null}

      {props.type === "registration" ? (
        <div className="reservation-details__row">
          <div className="reservation-details__icon">
            <FontAwesomeIcon
              size="2x"
              style={{ color: props.appearance.highlightBackgroundColor || "green" }}
              icon={faUser}
            ></FontAwesomeIcon>
          </div>
          <div className="reservation-details__text">{reservationData.name}</div>
        </div>
      ) : null}
      {reservationData.email ? (
        <div className="reservation-details__row">
          <div className="reservation-details__icon">
            <FontAwesomeIcon
              size="2x"
              style={{ color: props.appearance.highlightBackgroundColor || "green" }}
              icon={faAt}
            ></FontAwesomeIcon>
          </div>
          <div className="reservation-details__text">{reservationData.email}</div>
        </div>
      ) : null}
      <div className="reservation-details__row">
        <div className="reservation-details__icon">
          <FontAwesomeIcon
            size="2x"
            style={{ color: props.appearance.highlightBackgroundColor || "green" }}
            icon={faMapMarkerAlt}
          ></FontAwesomeIcon>
        </div>
        <div className="reservation-details__text">{reservationData.location}</div>
      </div>
    </div>
  );
}

export default ReservationDetails;
