import { routes } from "../api/routes";

export async function logError(name, error, params) {
  if (!error) {
    return;
  }
  const body = {
    name: name,
    message: error.message,
    stack: error.stack,
    fileName: error.filename,
    lineNo: error.lineno,
    params,
    useragent: navigator.userAgent
  };
  return fetch(`${routes.logError}`, {
    method: "POST",
    body: JSON.stringify(body),
    headers: {
      Accepts: "application/json"
    }
  })
    .then(response => {
      console.log(response);
      if (!response.ok || (response.status > 200 && response.status < 600)) {
        console.error("error triggering loggingError function", response);
      }
      return response;
    })
    .catch(error => {
      console.error("error triggering loggingError function", error);
      throw error;
    });
}
