import { Modal, Button } from "react-bootstrap";

function LocationNoticeModal({ show, handleOk }) {
  return (
    <Modal className="static-modal" show={show}>
      <Modal.Header>
        <Modal.Title>Beachwood Cafe needs your location</Modal.Title>
      </Modal.Header>

      <Modal.Body>
        <span>
          Beachwood Cafe would like to check that you are close enough to use Waitlist check-in. Please allow location
          services if prompted.
        </span>
      </Modal.Body>

      <Modal.Footer>
        <Button bsStyle="primary" onClick={handleOk}>
          Ok
        </Button>
      </Modal.Footer>
    </Modal>
  );
}

export default LocationNoticeModal;
