export const isValidLocation = selectedLocation => {
  return Boolean(
    selectedLocation &&
      selectedLocation.settings &&
      selectedLocation.settings.address &&
      selectedLocation.settings.address.address1 &&
      selectedLocation.settings.address.city &&
      selectedLocation.settings.address.postal
  );
};
