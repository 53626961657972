import React, { useEffect, useState } from "react";
import { Form, Row, Col, FormGroup, Button } from "react-bootstrap";
import "./AddGuests.scss";
import { useForm } from "react-hook-form";
import { createPendingVisit } from "../../services/checkin.service";
import { useNavigate } from "react-router-dom";
import BeatLoader from "react-spinners/BeatLoader";
import { Checkbox } from "@material-ui/core";
import { formatPhone } from "../../util/phoneUtils";
import { useSelector } from "react-redux";
import { Business } from "types/business";

import { BuildCusmomizableInputsForm } from "components/CustomizableInputFiels/BuildCustomizableInputFields";
import { CustomizableInputFieldOption, CustomizableInputFieldType } from "types/customizable-input-fields";

function AddGuestForm(props) {
  const { register, handleSubmit, errors, reset } = useForm();
  const emptyForm = {
    name: "",
    partySize: "",
    phone: "",
    email: ""
  };
  const [widgetMode, setWidgetMode] = useState(false);
  const navigate = useNavigate();
  const [form] = useState({ ...emptyForm });
  const [loading, setLoading] = useState(false);
  const [message, setMessage] = useState("");
  const [isEmailFlow, setIsEmailFlow] = useState(false);
  const [business] = useState(props.business as Business);
  const [services] = useState(props.business.settings.services);
  const [rememberMe, setRememberMe] = useState(true);
  const [name, setName] = useState(localStorage.getItem(props.businessId + "name") || undefined);
  const [quickCheckIn, setQuickCheckIn] = useState(false);
  const { display } = useSelector(state => {
    return state;
  }) as any;

  useEffect(() => {
    if (display && display.widgetMode !== undefined) {
      setWidgetMode(display.widgetMode);
    } else {
      setWidgetMode(false);
    }
    setQuickCheckIn(
      !!(
        localStorage.getItem(props.businessId + "name") &&
        localStorage.getItem(props.businessId + "previousConfirmation")
      )
    );
  }, []);

  const getNotificationType = () => {
    if (props?.business?.settings?.notificationType) {
      return business.settings.notificationType;
    }
    if (props?.accountSettings?.notificationType) {
      return props.accountSettings.notificationType;
    }
    return "sms";
  };

  useEffect(() => {
    setIsEmailFlow(getNotificationType() === "email");
  }, [props]);

  const updateRememberMe = event => {
    setRememberMe(event.target.checked);
  };

  const dontRemember = () => {
    localStorage.removeItem(props.businessId + "name");
    localStorage.removeItem(props.businessId + "previousConfirmation");
    setName("");
    setRememberMe(false);
    setQuickCheckIn(false);
  };

  const onSubmit = data => {
    console.log("the form name: ", data, "props: ", props.service, "data", data.service);
    if (loading) {
      return;
    }
    setLoading(true);
    if (props.service && !data.service) {
      data.service = props.service;
    }
    if (name) {
      data.name = name;
    }
    const previousConfirmation = localStorage.getItem(props.businessId + "previousConfirmation");
    if (rememberMe) {
      localStorage.setItem(props.businessId + "name", data.name);
    }
    createPendingVisit(props.businessId, { ...data, phone: formatPhone(data.phone), previousConfirmation, isEmailFlow })
      .then(response => response.json())
      .then(result => {
        setLoading(false);
        reset({ ...emptyForm });
        document.body.scrollTop = 0; // For Safari
        document.documentElement.scrollTop = 0;
        if (result.hash) {
          if (result.visitId) {
            localStorage.setItem(props.businessId + "previousConfirmation", result.visitId);
          }
          if (result.shouldRedirectToErrorPage) {
            navigate(`/checkin-exists-error/${props.businessId}?hash=${result.hash}`);
          } else {
            navigate(`/checkin-successful/${props.businessId}?hash=${result.hash}`);
          }
        } else {
          navigate(
            `/checkin-confirm/${props.businessId}?pendingVisitId=${result.id}${
              result.code ? `&code=${result.code}` : ""
            }`
          );
        }
      })
      .catch(() => {
        setLoading(false);
        setMessage("There was an issue with processing your request");
      });
  };

  const showBusinessName = (business, appearance) => {
    if (appearance && appearance.logoUrl) {
      return business.name;
    } else {
      return "";
    }
  };

  var buttonStyle = {
    backgroundColor: props.appearance.highlightBackgroundColor,
    color: props.appearance.highlightFontColor,
    width: "100%",
    fontFamily: props.appearance.fontFamily
  };

  return (
    <div className="add-guest-wrapper">
      <Form noValidate className="add-guest" onSubmit={handleSubmit(onSubmit)}>
        {quickCheckIn ? (
          <h5>Welcome Back {name}!</h5>
        ) : (
          <>{!widgetMode && <h5>{showBusinessName(business, props.appearance)} Waitlist Check-in</h5>}</>
        )}
        {!quickCheckIn && (
          <>
            <Row>
              <Col className="add-guest__title" md={12} style={{ fontSize: "14px" }}>
                Please enter your info to be added to our waitlist and we'll text you when it's your turn.
              </Col>
            </Row>
          </>
        )}
        <BuildCusmomizableInputsForm
          business={business}
          errors={errors}
          form={form}
          register={register}
          type="selfCheckIn"
          visit={props.visit}
          quickCheckIn={quickCheckIn}
          maxPartySize={props.maxPartySize}
          overrideFields={
            isEmailFlow
              ? {
                  [CustomizableInputFieldType.EMAIL]: CustomizableInputFieldOption.REQUIRED,
                  [CustomizableInputFieldType.PHONE_NUMBER]: CustomizableInputFieldOption.HIDDEN
                }
              : undefined
          }
        />
        {!quickCheckIn && (
          <Row>
            <Col md={12}>
              <FormGroup className="add-guest__form-group" style={{ flexDirection: "row" }}>
                <Checkbox
                  style={{ color: props.appearance.highlightBackgroundColor }}
                  size="medium"
                  defaultChecked={true}
                  name="rememberMe"
                  onChange={updateRememberMe}
                />
                <span style={{ fontSize: "14px", textAlign: "left" }}>Remember me for faster check-in next time. </span>
              </FormGroup>
            </Col>
          </Row>
        )}
        <Row>
          <Col md={12}>
            <div className="add-guest__actions">
              {loading ? (
                <BeatLoader margin={2.5} size={10} color={"#447df7"} />
              ) : (
                <>
                  {quickCheckIn || isEmailFlow ? (
                    <Button style={buttonStyle} type="submit">
                      Add me to the waitlist
                    </Button>
                  ) : (
                    <Button style={buttonStyle} type="submit">
                      Verify my phone and add me
                    </Button>
                  )}
                </>
              )}
            </div>
          </Col>
        </Row>
        {quickCheckIn && (
          <Row>
            <Col md={12}>
              <div className="add-guest__actions" style={{ paddingTop: "10px" }}>
                <a
                  style={{ color: props.appearance.highlightBackgroundColor, cursor: "pointer" }}
                  type="submit"
                  onClick={dontRemember}
                >
                  Not {name}?
                </a>
              </div>
            </Col>
          </Row>
        )}
        <Row>
          <Col md={12}>
            <div style={{ textAlign: "left", marginTop: "10px" }}>
              <span style={{ fontSize: "12px", textAlign: "left !important" as any }}>
                {" "}
                By submiting the form you consent to receiving text messages and emails from the Waitly Platform on
                behalf of {props.accountName}. See our{" "}
                <a style={{ color: "#4D6CC1" }} href="https://waitly.com/privacy">
                  Privacy Policy
                </a>{" "}
                here.
              </span>
            </div>
          </Col>
        </Row>
        {message ? (
          <Row>
            <Col md={12}>
              <div className="add-guest__message">{message}</div>
            </Col>
          </Row>
        ) : null}
      </Form>
    </div>
  );
}

export default AddGuestForm;
