import React from "react";
import { useForm } from "react-hook-form";
import { Form, FormGroup, Button } from "react-bootstrap";
import GoogleMap from "../GoogleMap/GoogleMap";
import { isValidLocation } from "../../util/locationUtil";

function SelectLocation(props) {
  const appearance = props.appearance;
  const { register, handleSubmit, errors } = useForm();
  const buttonStyle = { fontFamily: props.appearance.fontFamily };
  const [selectedLocation, setSelectedLocation] = React.useState("");
  const [mapLoading, setMapLoading] = React.useState(false);

  const onSubmit = data => {
    props.selectLocationCallback(data.location);
  };

  const handleLocationChoosen = e => {
    if (props.businesses) {
      setSelectedLocation(props.businesses.find(el => el.id === e.target.value));
    }
  };

  const content = (
    <Form className="self-reservations__form" noValidate onSubmit={handleSubmit(onSubmit)}>
      <div className="self-reservations__title">SELECT A LOCATION</div>
      <FormGroup className="self-reservations__input" validationState={(!!errors.location && "error") || null}>
        <select
          className="form-control "
          ref={register({ required: true })}
          placeholder="Select an option"
          name="location"
          onChange={handleLocationChoosen}
          value={selectedLocation?.id || ""}
        >
          <option value="">Select a Location</option>
          {props.businesses.map((business, index) => (
            <option key={index} value={business.id}>
              {business.name}
            </option>
          ))}
        </select>
      </FormGroup>
      <div className="self-reservations__button-wrp" style={{ paddingBottom: 20 }}>
        <Button style={buttonStyle} type="submit">
          Select
        </Button>
      </div>
    </Form>
  );

  const renderContent = () => {
    return (
      <div className="self-reservations">
        {props.businesses && (
          <>
            {content}
            <GoogleMap
              withLeftSeperator
              loading={mapLoading}
              setLoading={setMapLoading}
              isLocationValid={isValidLocation(selectedLocation)}
              location={selectedLocation}
            />
          </>
        )}
      </div>
    );
  };

  return <div style={{ background: appearance.addGuestBackground }}>{renderContent()}</div>;
}

export default SelectLocation;
