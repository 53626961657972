const ROOT_URL = `/waitlist/api/`;

export const routes = {
  cancelVisit: `${ROOT_URL}cancelVisit`,
  getWaitlist: `${ROOT_URL}getWaitlist`,
  checkInData: `${ROOT_URL}checkInData`,
  getBusinessDetails: `${ROOT_URL}getBusinessDetails`,
  getReservationData: `${ROOT_URL}getReservationData`,
  getPublicWaitlist: `${ROOT_URL}getPublicWaitlist`,
  createPendingVisit: `${ROOT_URL}createPendingVisit`,
  verifyPendingVisitCode: `${ROOT_URL}verifyPendingVisitCode`,
  bookReservation: `${ROOT_URL}bookReservation`,
  putToWaitlist: `${ROOT_URL}putToWaitlist`,
  logError: `${ROOT_URL}logError`,
  getSelfBookingAvailableSlots: `${ROOT_URL}selfBooking/slots`,
  createReservationWithPendingStatus: `${ROOT_URL}selfBooking/pending`,
  confirmPendingReservation: `${ROOT_URL}selfBooking/confirm`,
  editReservation: `${ROOT_URL}selfBooking/edit`,
  cancelReservation: `${ROOT_URL}selfBooking/cancel`,
  getReservationById: `${ROOT_URL}reservation`,
  getResourcesByBusinessId: `${ROOT_URL}resources`
};
