import { useEffect } from "react";

function Page({ element, title }) {
  useEffect(() => {
    document.title = title;
    window.dataLayer.push({
      event: "virtualPageView",
      pageUrl: document.location.href,
      pageTitle: title
    });
  }, [title]);
  return element;
}

export default Page;
