import React, { useEffect } from "react";
import image from "../imgs/PoweredByBlue.svg";
import { logError } from "../services/log.service";

function NotFound() {
  useEffect(() => {
    try {
      logError("404 Not Found", "page not found", { url: window.location.href });
    } catch (e) {
      console.log(e);
    }
  }, []);
  return (
    <div className="loading" style={{ padding: "5px" }}>
      <h1>404 Not Found</h1>
      <h3>Try copying and pasting your link to ensure you have the full URL.</h3>
      <a style={{ marginTop: "250px" }} href="https://waitlyapp.com">
        <img src={image} alt="Waitly Logo" />
      </a>
    </div>
  );
}

export default NotFound;
