import React, { Component } from "react";
import { Button } from "react-bootstrap";
import { connect } from "react-redux";
import { cancelVisit } from "../actions";
import CancelModel from "./CancelModal";
import { selectedVisitor } from "../model/waitlistFunctions";
import { routes } from "../api/routes";

class CancelButton extends Component {
  constructor() {
    super();
    this.state = { showCancelDialog: false };
  }

  confirmCancel() {
    this.setState({ showCancelDialog: true });
  }

  cancelVisit() {
    var visit = selectedVisitor(this.props.waitlist.visits);
    this.props.cancelVisit();
    this.setState({ showCancelDialog: false });
    fetch(`${routes.cancelVisit}?id=${visit.visitId}`)
      .then(response => response.json())
      .then(json => {})
      .catch(err => {
        console.log(err);
      });
  }

  handleYes() {
    this.cancelVisit();
  }

  handleNo() {
    this.setState({ showCancelDialog: false });
  }

  render() {
    var buttonStyle = {
      fontFamily: this.props.appearance.fontFamily,
      color: this.props.appearance.fontColor,
      borderColor: this.props.appearance.fontColor
    };
    var visit = selectedVisitor(this.props.waitlist.visits);
    if (visit === undefined) {
      buttonStyle = { display: "none", color: "white" };
    }
    return (
      <div>
        <CancelModel show={this.state.showCancelDialog} onYes={() => this.handleYes()} onNo={() => this.handleNo()} />
        <Button style={buttonStyle} onClick={() => this.confirmCancel()}>
          Remove me from list
        </Button>
      </div>
    );
  }
}

export default connect(null, { cancelVisit })(CancelButton);
