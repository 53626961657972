import React from 'react';
import Logo from "../../ui/Logo";
import waitlyLogo from '../../imgs/PoweredBy.svg';

const GlobalWaitlistFooter = ({
  appearance: {
    footerBackgroundColor
  }}) => {

  return(
    <div
      style={{
        background: footerBackgroundColor
      }}
      className='global-waitlist-footer'>
      <Logo
        imageSrc={waitlyLogo}
        alt={'waitly-logo'}
      />
    </div>
  )
}

export default GlobalWaitlistFooter;