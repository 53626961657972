import React from 'react';

const Logo = ({
  imageSrc,
  alt,
  className,
              }) => {

  return(
    <img className={className} src={imageSrc} alt={alt} />
  )

}

export default Logo