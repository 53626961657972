import {
  CustomizableInputFieldOption,
  CustomizableInputFieldType,
  CustomizableInputFields,
  CustomizableInputFieldsKeys
} from "types/customizable-input-fields";

export const AVAILABLE_OPTIONS_FOR_CUSTOMIZABLE_INPUT_FIELDS: {
  [key in CustomizableInputFieldsKeys]: {
    reservations?: CustomizableInputFieldOption[];
    selfCheckIn?: CustomizableInputFieldOption[];
    waitlist?: CustomizableInputFieldOption[];
  };
} = {
  [CustomizableInputFieldType.NAME]: {},
  [CustomizableInputFieldType.PHONE_NUMBER]: {
    reservations: [
      CustomizableInputFieldOption.REQUIRED,
      CustomizableInputFieldOption.OPTIONAL,
      CustomizableInputFieldOption.HIDDEN
    ],
    selfCheckIn: [CustomizableInputFieldOption.REQUIRED],
    waitlist: [
      CustomizableInputFieldOption.REQUIRED,
      CustomizableInputFieldOption.OPTIONAL,
      CustomizableInputFieldOption.HIDDEN
    ]
  },
  [CustomizableInputFieldType.EMAIL]: {
    reservations: [
      CustomizableInputFieldOption.REQUIRED,
      CustomizableInputFieldOption.OPTIONAL,
      CustomizableInputFieldOption.HIDDEN
    ],
    selfCheckIn: [
      CustomizableInputFieldOption.REQUIRED,
      CustomizableInputFieldOption.OPTIONAL,
      CustomizableInputFieldOption.HIDDEN
    ],
    waitlist: [
      CustomizableInputFieldOption.REQUIRED,
      CustomizableInputFieldOption.OPTIONAL,
      CustomizableInputFieldOption.HIDDEN
    ]
  },
  [CustomizableInputFieldType.PARTY_SIZE]: {
    reservations: [CustomizableInputFieldOption.REQUIRED, CustomizableInputFieldOption.HIDDEN],
    selfCheckIn: [CustomizableInputFieldOption.REQUIRED, CustomizableInputFieldOption.HIDDEN],
    waitlist: [CustomizableInputFieldOption.REQUIRED, CustomizableInputFieldOption.HIDDEN]
  },
  [CustomizableInputFieldType.SERVICE]: {
    selfCheckIn: [
      CustomizableInputFieldOption.REQUIRED,
      CustomizableInputFieldOption.OPTIONAL,
      CustomizableInputFieldOption.HIDDEN
    ],
    waitlist: [
      CustomizableInputFieldOption.REQUIRED,
      CustomizableInputFieldOption.OPTIONAL,
      CustomizableInputFieldOption.HIDDEN
    ]
  },
  [CustomizableInputFieldType.QUOTED_TIME]: {
    waitlist: [
      CustomizableInputFieldOption.REQUIRED,
      CustomizableInputFieldOption.OPTIONAL,
      CustomizableInputFieldOption.HIDDEN
    ]
  },
  [CustomizableInputFieldType.ORDER_NUMBER]: {
    waitlist: [
      CustomizableInputFieldOption.REQUIRED,
      CustomizableInputFieldOption.OPTIONAL,
      CustomizableInputFieldOption.HIDDEN
    ]
  }
};

export const CUSTOMIZABLE_INPUT_FIELDS_LIST = [
  {
    name: CustomizableInputFieldType.NAME,
    label: "Name"
  },
  {
    name: CustomizableInputFieldType.PHONE_NUMBER,
    label: "Phone Number"
  },
  {
    name: CustomizableInputFieldType.EMAIL,
    label: "Email"
  },
  {
    name: CustomizableInputFieldType.PARTY_SIZE,
    label: "Party Size"
  },
  {
    name: CustomizableInputFieldType.SERVICE,
    label: "Service"
  },
  {
    name: CustomizableInputFieldType.QUOTED_TIME,
    label: "Estimated Wait"
  },
  {
    name: CustomizableInputFieldType.ORDER_NUMBER,
    label: "Order Number"
  }
];

export const DEFAULT_CUSTOMIZABLE_INPUT_FIELDS: CustomizableInputFields = {
  name: {
    enabled: true,
    reservations: CustomizableInputFieldOption.REQUIRED,
    selfCheckIn: CustomizableInputFieldOption.REQUIRED,
    waitlist: CustomizableInputFieldOption.REQUIRED
  },
  phoneNumber: {
    enabled: true,
    reservations: CustomizableInputFieldOption.OPTIONAL,
    selfCheckIn: CustomizableInputFieldOption.REQUIRED,
    waitlist: CustomizableInputFieldOption.OPTIONAL
  },
  email: {
    enabled: true,
    reservations: CustomizableInputFieldOption.HIDDEN,
    selfCheckIn: CustomizableInputFieldOption.HIDDEN,
    waitlist: CustomizableInputFieldOption.HIDDEN
  },
  partySize: {
    enabled: true,
    reservations: CustomizableInputFieldOption.REQUIRED,
    selfCheckIn: CustomizableInputFieldOption.REQUIRED,
    waitlist: CustomizableInputFieldOption.REQUIRED
  },
  service: {
    enabled: true,
    selfCheckIn: CustomizableInputFieldOption.REQUIRED,
    waitlist: CustomizableInputFieldOption.REQUIRED
  },
  orderNumber: {
    enabled: true,
    waitlist: CustomizableInputFieldOption.HIDDEN
  },
  quotedTime: {
    enabled: true,
    waitlist: CustomizableInputFieldOption.REQUIRED
  }
};

export const DEFAULT_CUSTOMIZABLE_FIELDS_ORDER = [
  CustomizableInputFieldType.NAME,
  `${CustomizableInputFieldType.PARTY_SIZE},${CustomizableInputFieldType.SERVICE}`,
  CustomizableInputFieldType.PHONE_NUMBER,
  CustomizableInputFieldType.EMAIL
];
