import React, { useEffect, useState } from "react";
import { isValidLocation } from "../../util/locationUtil";
import GoogleMap from "../GoogleMap/GoogleMap";
import { useMediaQuery } from "../../hooks/useMediaQuery";
import { DateTime } from "luxon";
import ClockIcon from "@material-ui/icons/AccessTime";
import CalendarIcon from "@material-ui/icons/Event";
import PersonIcon from "@material-ui/icons/Person";
import SearchSlotStep from "./SelfReservationSteps/SearchSlotStep";
import { logVirtualPageView } from "../../util/analytics";
import ConfirmStep from "./SelfReservationSteps/ConfirmStep";
import DetailsStep from "./SelfReservationSteps/DetailsStep";
import { useLocation, useNavigate } from "react-router-dom";
import { sessionStorageManager, SSItem } from "../../util/sessionStorageUtil";
import { formatPhone } from "../../util/phoneUtils";
import { confirmPendingReservation, getReservationById } from "../../services/reservation.service";
import { useSelector } from "react-redux";
import EditStep from "./SelfReservationSteps/EditStep";
import CancellationConfirmStep from "./SelfReservationSteps/CancellationConfirmStep";
import { VisitStatus } from "constants";

function Booking(props) {
  const BOOK_STEPS = {
    SEARCH_SLOTS: "SEARCH_SLOTS",
    DETAILS: "DETAILS",
    EDIT: "EDIT",
    CANCELLATION_CONFIRM: "CANCELLATION_CONFIRM",
    CONFIRM: "CONFIRM",
    CONFIRM_CHANGE: "CONFIRM_CHANGE"
  };
  const navigate = useNavigate();
  const appearance = props.appearance;
  const selectedLocation = props.business;
  const { display } = useSelector((state: any) => state || { map: true });
  const accountId = props.accountId;
  const [mapLoading, setMapLoading] = React.useState(false);
  const buttonStyle = {
    fontFamily: props.appearance.fontFamily,
    backgroundColor: props.appearance.highlightBackgroundColor,
    color: props.appearance.highlightFontColor
  };
  const [selectedDate, setSelectedDate] = useState<any>(null);
  const [selectedTime, setSelectedTime] = useState(null);
  const [selectedPartySize, setSelectedPartySize] = useState(null);
  const [contentLoading, setContentLoading] = useState(false);
  const [error, setError] = useState("");
  const isMobile = useMediaQuery("(max-width: 767px)");
  const SStimerManager = sessionStorageManager(SSItem.TIMER);
  const SSReservationInfoManager = sessionStorageManager(SSItem.RESERVATION_INFO);

  function useQuery() {
    return new URLSearchParams(useLocation().search);
  }

  const query = useQuery();

  const [step, setStep] = useState(
    SSReservationInfoManager.get() && query && query.has("step")
      ? query.get("step") === "details"
        ? BOOK_STEPS.DETAILS
        : BOOK_STEPS.SEARCH_SLOTS
      : query && query.has("step") && query.get("step") === "edit"
      ? BOOK_STEPS.EDIT
      : BOOK_STEPS.SEARCH_SLOTS
  );

  const onSubmitDetails = async values => {
    setContentLoading(true);
    try {
      const data = { ...values, phone: formatPhone(values.phone) };
      const res = await confirmPendingReservation(data);

      if (!res?.success || !res.data?.reservationId) {
        throw new Error(res?.error || res?.message);
      }
      setContentLoading(false);
      logVirtualPageView("Waitly - Reservation Confirmation");
      navigate(`?step=confirm&id=${res.data.reservationId || ""}`);
    } catch (e) {
      console.log(e);
      setError((e as any)?.message || "Something went wrong");
      setContentLoading(false);
      setStep(BOOK_STEPS.SEARCH_SLOTS);
      return;
    }
  };

  const reservationIsValidForEditing = reservation => {
    // TODO: check with Joe if we need to adjust statuses here
    const isValid =
      [VisitStatus.reserved, VisitStatus.waiting, VisitStatus.notified].includes(reservation.status) &&
      reservation.date.getTime() > new Date().getTime();
    return isValid;
  };

  const goBackToSearchSlots = () => {
    setStep(BOOK_STEPS.SEARCH_SLOTS);
    query.delete("step");
    query.delete("id");
    navigate(query as any);
  };

  const fetchReservation = async () => {
    if (query.has("id")) {
      const res = await getReservationById(query.get("id"));
      if (res.success) {
        const date = DateTime.fromFormat(res.data?.date, "yyyy-MM-dd");
        if (
          Reflect.has(res.data, "status") &&
          reservationIsValidForEditing({
            ...res.data,
            date: new Date(DateTime.fromSeconds(res.data.reservationTime._seconds).toISO())
          })
        ) {
          setSelectedPartySize(res.data?.partySize || 0);
          setSelectedDate(date.isValid ? date.toISO() : null);
          setSelectedTime(res.data?.time || null);
          return;
        } else {
          goBackToSearchSlots();
          return;
        }
      }
      goBackToSearchSlots();
      setError(res?.error || res?.message || "Something went wrong");
      return;
    }
  };

  useEffect(() => {
    if (step === BOOK_STEPS.DETAILS) {
      const timer = SStimerManager.get();
      if (!timer) SStimerManager.set(new Date());
      return;
    }
    if (step === BOOK_STEPS.CONFIRM || step === BOOK_STEPS.CONFIRM_CHANGE) {
      SStimerManager.remove();
      SSReservationInfoManager.remove();
      fetchReservation();
      return;
    }
    if (step === BOOK_STEPS.EDIT) {
      fetchReservation();
      return;
    }
    if (step === BOOK_STEPS.CANCELLATION_CONFIRM) {
      return;
    }
    if (step === BOOK_STEPS.SEARCH_SLOTS && query.has("id")) {
      fetchReservation();
      return;
    }
    // SEARCH_SLOTS without id
    SStimerManager.remove();
    SSReservationInfoManager.remove();
    query.delete("step");
    query.delete("id");
    //eslint-disable-next-line react-hooks/exhaustive-deps
  }, [step]);

  useEffect(() => {
    if (query && query.has("step")) {
      if (SSReservationInfoManager.get() && query.get("step") === "details") {
        const { date, time, partySize } = SSReservationInfoManager.get();
        setSelectedDate(date);
        setSelectedTime(time);
        setSelectedPartySize(partySize);
        setStep(BOOK_STEPS.DETAILS);
      } else if (query.get("step") === "confirm" && query.has("id") && !!query.get("id")) {
        setStep(BOOK_STEPS.CONFIRM);
      } else if (query.get("step") === "confirm-edit" && query.has("id") && !!query.get("id")) {
        setStep(BOOK_STEPS.CONFIRM_CHANGE);
      } else if (query.get("step") === "edit" && query.has("id") && !!query.get("id")) {
        setStep(BOOK_STEPS.EDIT);
      } else if (query.get("step") === "cancellation-confirm") {
        setStep(BOOK_STEPS.CANCELLATION_CONFIRM);
      } else if (query.has("id")) {
        setStep(BOOK_STEPS.SEARCH_SLOTS);
      }
    } else {
      setStep(BOOK_STEPS.SEARCH_SLOTS);
      SSReservationInfoManager.remove();
    }
    //eslint-disable-next-line react-hooks/exhaustive-deps
  }, [query]);

  const RESERVATION_DETAILS = (step === BOOK_STEPS.DETAILS ||
    step === BOOK_STEPS.CONFIRM ||
    step === BOOK_STEPS.CONFIRM_CHANGE ||
    step === BOOK_STEPS.EDIT) &&
    selectedPartySize &&
    selectedDate &&
    selectedTime && (
      <div style={{ marginBottom: 20 }}>
        <div className="self-reservations__title reservation-details small">
          <PersonIcon className="self-reservations__icon" style={{ fill: buttonStyle.backgroundColor }} />{" "}
          {selectedPartySize + ` ${selectedPartySize > 1 ? "people" : "person"}`}
        </div>
        <div className="self-reservations__title reservation-details small">
          <CalendarIcon style={{ fill: buttonStyle.backgroundColor }} />{" "}
          {DateTime.fromISO("" + selectedDate).toFormat("MM/dd/yyyy")}
        </div>
        <div className="self-reservations__title reservation-details small">
          <ClockIcon style={{ fill: buttonStyle.backgroundColor }} />{" "}
          {DateTime.fromFormat(selectedTime, "HH:mm").toFormat("hh:mm a")}
        </div>
      </div>
    );

  const pages = () => {
    switch (step) {
      case BOOK_STEPS.SEARCH_SLOTS: {
        return (
          // @ts-ignore
          <SearchSlotStep
            buttonStyle={buttonStyle}
            business={props.business}
            selectedLocation={selectedLocation}
            accountId={accountId}
            isMobile={isMobile}
            timezone={props.business?.settings?.timezone}
            error={error}
            setError={setError}
            reservationId={query.get("id")}
            selection={
              query.get("id") && selectedDate && selectedPartySize && selectedTime
                ? {
                    selectedDate: DateTime.fromISO("" + selectedDate).toFormat("yyyy-MM-dd"),
                    selectedPartySize,
                    selectedTime
                  }
                : null
            }
          />
        );
      }
      case BOOK_STEPS.DETAILS: {
        return (
          <DetailsStep
            buttonStyle={buttonStyle}
            selectedLocation={selectedLocation}
            isMobile={isMobile}
            onSubmit={d => onSubmitDetails(d)}
            loading={contentLoading}
          />
        );
      }
      case BOOK_STEPS.CONFIRM:
        return <ConfirmStep reservationDetails={RESERVATION_DETAILS} appearance={appearance} />;
      case BOOK_STEPS.CONFIRM_CHANGE:
        return <ConfirmStep reservationDetails={RESERVATION_DETAILS} isChangeConfirm appearance={appearance} />;
      case BOOK_STEPS.EDIT: {
        return (
          <EditStep reservationId={query.get("id")} reservationDetails={RESERVATION_DETAILS} appearance={appearance} />
        );
      }
      case BOOK_STEPS.CANCELLATION_CONFIRM: {
        return <CancellationConfirmStep />;
      }
      default: {
        return <>Something went wrong</>;
      }
    }
  };

  const renderContent = () => {
    return (
      <div className={`self-reservations ${step === BOOK_STEPS.CONFIRM ? "confirm-page" : ""}`}>
        {selectedLocation && (
          <>
            {pages()}
            {!display.widgetMode && (
              <GoogleMap
                reservationDetails={!isMobile && step === BOOK_STEPS.DETAILS && RESERVATION_DETAILS}
                withLeftSeperator
                loading={mapLoading}
                setLoading={setMapLoading}
                isLocationValid={isValidLocation(selectedLocation)}
                location={selectedLocation}
              />
            )}
          </>
        )}
      </div>
    );
  };

  return <div style={{ background: appearance.addGuestBackground }}>{renderContent()}</div>;
}

export default Booking;
