import React from "react";
import { Button } from "react-bootstrap";
import { useNavigate } from "react-router-dom";
import ReservationAcknowledgment from "./ReservationAcknowledgment";
import ReservationDetails from "./ReservationDetails";

function ReservationSuccessful(props) {
  const navigate = useNavigate();

  function change() {
    navigate(`/r/${props.shortId}/edit`);
  }
  return (
    <div className="reservation-successful">
      <ReservationAcknowledgment appearance={props.appearance}></ReservationAcknowledgment>
      <ReservationDetails
        timezone={props.timezone}
        reservationData={props.reservationData}
        appearance={props.appearance}
      ></ReservationDetails>
      <Button
        style={{ fontFamily: props.appearance.fontFamily, background: props.appearance.fontColor }}
        className="reservation-button reservation-button--margin"
        onClick={change}
      >
        Change
      </Button>
    </div>
  );
}

export default ReservationSuccessful;
