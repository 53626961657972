import React from "react";

const GlobalWaitlistSidebar = ({ waitTimes, appearance: { sidebarBackgroundColor, sidebarTextColor } }) => {
  const showTotalAverageTime = () => {
    if (waitTimes && Object.keys(waitTimes).length === 1) {
      return Object.keys(waitTimes)[0] === "currentWait";
    }
  };

  const getNumCategories = waitTimes => {
    return waitTimes && Object.keys(waitTimes).length;
  };

  return (
    <div style={{ background: sidebarBackgroundColor }} className="waitlist-sidebar-wrapper">
      <div style={{ color: sidebarTextColor }} className="waitlist-sidebar-title">
        {!showTotalAverageTime() ? "Wait Times" : "Average Wait"}
      </div>
      {!showTotalAverageTime() ? (
        waitTimes &&
        Object.keys(waitTimes).map(
          (key, index) =>
            key !== "Curbside" && (
              <div
                key={index}
                className={`waitlist-sidebar-pickup${getNumCategories(waitTimes) > 4 ? " compact" : ""}`}
              >
                <div
                  style={{ color: sidebarTextColor }}
                  className={`waitlist-sidebar-pickup-title${getNumCategories(waitTimes) > 4 ? " compact" : ""}`}
                >
                  {key}
                </div>
                <div style={{ color: sidebarTextColor }} className="waitlist-sidebar-time ">
                  {`${waitTimes[key]} min`}
                </div>
              </div>
            )
        )
      ) : (
        <div className="waitlist-sidebar-pickup">
          <div style={{ color: sidebarTextColor }} className="waitlist-sidebar-time ">
            {`${waitTimes["currentWait"]} min`}
          </div>
        </div>
      )}
    </div>
  );
};

export default GlobalWaitlistSidebar;
