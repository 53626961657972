import React from "react";
import { Row, Col, HelpBlock, FormGroup, ControlLabel } from "react-bootstrap";
import InputMask from "react-input-mask";
import { parsePhoneNumberFromString } from "libphonenumber-js";
import {
  DEFAULT_CUSTOMIZABLE_FIELDS_ORDER,
  DEFAULT_CUSTOMIZABLE_INPUT_FIELDS
} from "constants/customizable-input-fields";
import { Business } from "types/business";
import {
  CustomizableInputFieldOption,
  CustomizableInputFieldType,
  CustomizableInputFieldsKeys
} from "types/customizable-input-fields";
import { EMAIL_PATTERN } from "constants/patterns";

export const getInputFields = (
  business: Business,
  type: "waitlist" | "reservations" | "selfCheckIn",
  overrideFields?: {
    [key in CustomizableInputFieldsKeys]?: CustomizableInputFieldOption;
  }
) => {
  let fieldOrder = DEFAULT_CUSTOMIZABLE_FIELDS_ORDER;
  let fields = DEFAULT_CUSTOMIZABLE_INPUT_FIELDS;
  if (business?.settings?.fields?.fieldOrder) {
    const { fieldOrder: _fieldOrder, ..._fields } = business.settings.fields;
    fieldOrder = _fieldOrder;
    fields = _fields;
  }
  if (overrideFields) {
    fields = {
      ...fields,
      ...Object.keys(overrideFields)
        .map(key => ({
          [key]: {
            enabled: true,
            reservations: overrideFields[key],
            selfCheckIn: overrideFields[key],
            waitlist: overrideFields[key]
          }
        }))
        .reduce((acc, curr) => ({ ...acc, ...curr }), {})
    };
  }

  const parsedFieldOrder = (fieldOrder.map(field => field.split(",")) as CustomizableInputFieldsKeys[][])
    .map(field =>
      field
        .filter(
          f =>
            fields[f]?.enabled &&
            !!fields[f]?.[type] &&
            (fields[f]?.[type] === CustomizableInputFieldOption.REQUIRED ||
              fields[f]?.[type] === CustomizableInputFieldOption.OPTIONAL) &&
            (f === CustomizableInputFieldType.SERVICE ? !!business?.settings?.services?.length : true)
        )
        .map(f => ({
          name: f,
          validation: fields[f]?.[type]
        }))
    )
    .filter(field => field.length);

  return parsedFieldOrder;
};

const formatPhone = phone => {
  if (isOnlyMaskPresent(phone)) {
    return "";
  }

  return phone?.replace(/[-_()\s]/gi, "").slice(0, 12);
};

const isOnlyMaskPresent = phone => {
  return phone === "+1 (___) ___-____";
};

const validatePhoneNumber = value => {
  if (!value || isOnlyMaskPresent(value)) {
    return true;
  }

  const phoneValue = formatPhone(value);
  const phone = parsePhoneNumberFromString(phoneValue || "");
  return !!phone && phone.isValid() && (phone.country === "US" || phone.country === "CA");
};

export const formFunctions: (props: {
  errors;
  register;
  form;
  business: Business;
  maxPartySize?: number;
}) => {
  [key in CustomizableInputFieldsKeys]?: (validation?: CustomizableInputFieldOption) => React.ReactElement;
} = ({ errors, register, form, business, maxPartySize }) => ({
  name: (validation?: CustomizableInputFieldOption) => {
    return (
      <FormGroup className="add-guest__form-group" validationState={(!!errors.name && "error") || null}>
        <ControlLabel>NAME</ControlLabel>
        <input
          name="name"
          className="form-control"
          type="text"
          placeholder="Enter name"
          autoFocus
          defaultValue={form.name}
          ref={register({ required: validation === CustomizableInputFieldOption.REQUIRED, maxLength: 30 })}
        />
        {errors.name && errors.name.type === "required" && <HelpBlock>Name is required</HelpBlock>}
        {errors.name && errors.name.type === "maxLength" && (
          <HelpBlock>Name length should be less than 30 characters</HelpBlock>
        )}
      </FormGroup>
    );
  },
  partySize: (validation?: CustomizableInputFieldOption) => {
    return (
      <FormGroup className="add-guest__form-group" validationState={(!!errors.partySize && "error") || null}>
        <ControlLabel>PARTY SIZE</ControlLabel>
        <input
          className="form-control"
          name="partySize"
          type="number"
          placeholder="Enter size"
          defaultValue={form.partySize}
          ref={register({ min: 1, max: maxPartySize, required: validation === CustomizableInputFieldOption.REQUIRED })}
        />
        {errors.partySize && errors.partySize.type === "min" && (
          <HelpBlock>Party size should be an integer greater 0</HelpBlock>
        )}
        {errors.partySize && errors.partySize.type === "max" && <HelpBlock>Max party size is {maxPartySize}</HelpBlock>}
        {errors.partySize && errors.partySize.type === "required" && <HelpBlock>Party size is required</HelpBlock>}
      </FormGroup>
    );
  },
  phoneNumber: (validation?: CustomizableInputFieldOption) => {
    return (
      <FormGroup className="add-guest__form-group" validationState={(!!errors.phone && "error") || null}>
        <ControlLabel>PHONE NUMBER</ControlLabel>
        <InputMask
          name="phone"
          className="form-control"
          type="text"
          placeholder="Enter phone"
          defaultValue={form.phone}
          ref={register({
            required: validation === CustomizableInputFieldOption.REQUIRED,
            validate: value => validatePhoneNumber(value)
          })}
          mask="+1 (999) 999-9999"
        />
        {errors.phone?.type === "validate" && <HelpBlock>Invalid phone number</HelpBlock>}
        {errors.phone?.type === "required" && <HelpBlock>Phone number is required</HelpBlock>}
      </FormGroup>
    );
  },
  service: (validation?: CustomizableInputFieldOption) => {
    if (business.settings && business.settings.services && business.settings.services.length) {
      return (
        <FormGroup className="add-guest__form-group" validationState={(!!errors.service && "error") || null}>
          <ControlLabel>PREFERENCE</ControlLabel>
          <select
            className="form-control"
            name="service"
            ref={register({ required: validation === CustomizableInputFieldOption.REQUIRED })}
            defaultValue={form.service || ""}
          >
            <option value="">Select a Preference</option>
            {business.settings.services.map((service, index) => {
              return (
                <option key={index} value={service.name}>
                  {service.name}
                </option>
              );
            })}
          </select>
          {errors.service?.type === "required" && <HelpBlock>Please select a Preference</HelpBlock>}
        </FormGroup>
      );
    } else {
      return <></>;
    }
  },
  email: (validation?: CustomizableInputFieldOption) => {
    return (
      <FormGroup className="add-guest__form-group" validationState={(!!errors.email && "error") || null}>
        <ControlLabel>EMAIL</ControlLabel>
        <input
          name="email"
          className="form-control"
          type="email"
          placeholder="Enter email"
          defaultValue={form.email}
          ref={register({
            required: validation === CustomizableInputFieldOption.REQUIRED,
            pattern: {
              value: EMAIL_PATTERN,
              message: "Please enter a valid email"
            }
          })}
        />
        {errors.email?.type === "required" && <HelpBlock>Email is required</HelpBlock>}
        {errors.email?.type === "pattern" && <HelpBlock>{errors.email.message}</HelpBlock>}
      </FormGroup>
    );
  }
});

export const BuildCusmomizableInputsForm = <T,>({
  business,
  form,
  visit,
  register,
  errors,
  type,
  quickCheckIn,
  maxPartySize,
  overrideFields
}: {
  business: Business;
  form: T;
  visit: any;
  register;
  errors: any;
  type: "waitlist" | "reservations" | "selfCheckIn";
  quickCheckIn: boolean;
  maxPartySize?: number;
  overrideFields?: {
    [key in CustomizableInputFieldsKeys]?: CustomizableInputFieldOption;
  };
}) => {
  const fields = getInputFields(business, type, overrideFields)
    .map(fields =>
      fields.filter(field => {
        if (quickCheckIn) {
          if ([CustomizableInputFieldType.NAME, CustomizableInputFieldType.PHONE_NUMBER].includes(field?.name)) {
            return false;
          } else if (field?.name === CustomizableInputFieldType.EMAIL && visit?.email !== undefined) {
            return false;
          } else {
            return true;
          }
        }
        return true;
      })
    )
    .filter(fields => fields.length);

  return (
    <>
      {fields.map((field, idx) => {
        const subFields = field;
        if (subFields.length === 2) {
          return (
            <React.Fragment key={"waitlist-field" + idx}>
              <Row key={"waitlist-field-first" + idx}>
                <Col md={12}>
                  {formFunctions({ form, register, errors, business, maxPartySize })?.[subFields[0].name]?.(
                    subFields[0].validation
                  )}
                </Col>
              </Row>
              <Row key={"waitlist-field-second" + idx}>
                <Col md={12}>
                  {formFunctions({ form, register, errors, business, maxPartySize })?.[subFields[1].name]?.(
                    subFields[1].validation
                  )}
                </Col>
              </Row>
            </React.Fragment>
          );
        } else {
          return (
            <Row key={"waitlist-field-" + idx}>
              <Col md={12}>
                {formFunctions({ form, register, errors, business, maxPartySize })?.[subFields[0].name]?.(
                  subFields[0].validation
                )}
              </Col>
            </Row>
          );
        }
      })}
    </>
  );
};
