import React, { useState, useEffect } from "react";
import { validatePhoneNumber } from "../../../util/phoneUtils";
import { Form, FormGroup, Button, HelpBlock } from "react-bootstrap";
import { useForm } from "react-hook-form";
import ReactInputMask from "react-input-mask";
import { DateTime } from "luxon";
import { sessionStorageManager, SSItem } from "../../../util/sessionStorageUtil";
import BeatLoader from "react-spinners/BeatLoader";

const DetailsStep = ({ isMobile, buttonStyle, selectedLocation, onSubmit, loading }) => {
  const timerStartInSeconds = 300;
  const { register, handleSubmit, errors } = useForm();
  const SStimerManager = sessionStorageManager(SSItem.TIMER);
  const SSReservationManager = sessionStorageManager(SSItem.RESERVATION_INFO);
  const getTimeDiffFromNow = () => {
    return Math.abs(DateTime.fromJSDate(new Date(SStimerManager.get()) || new Date()).diffNow("seconds").seconds);
  };
  const [timer, setTimer] = useState(timerStartInSeconds - getTimeDiffFromNow());

  useEffect(() => {
    const interval = setInterval(() => {
      setTimer(timerStartInSeconds - getTimeDiffFromNow());
    }, 1000);

    return () => {
      clearInterval(interval);
    };
    //eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <Form
      className="self-reservations__form"
      noValidate
      onSubmit={handleSubmit(dt =>
        onSubmit({ ...dt, ...SSReservationManager.get(), reservationId: SSReservationManager.get().id })
      )}
    >
      {!isMobile && <div className="self-reservations__title">Enter your details</div>}
      {SStimerManager.get() &&
        (timer < 0 ? (
          <div className="self-reservations__hold-spot-text" style={{ color: "red" }}>
            You can still try to complete your reservation, but this time may no longer be available.
          </div>
        ) : (
          <div className="self-reservations__hold-spot-text">
            Please, complete your details. We'll hold your spot for{" "}
            <strong>{DateTime.fromSeconds(timer).toFormat("m:ss")}</strong> minutes
          </div>
        ))}
      <FormGroup className="self-reservations__input" validationState={(!!errors.firstName && "error") || null}>
        <input className="form-control " ref={register({ required: true })} placeholder="First Name" name="firstName" />
      </FormGroup>
      <FormGroup className="self-reservations__input" validationState={(!!errors.lastName && "error") || null}>
        <input className="form-control " ref={register({ required: true })} placeholder="Last Name" name="lastName" />
      </FormGroup>
      <FormGroup className="self-reservations__input" validationState={(!!errors.phone && "error") || null}>
        <ReactInputMask
          name="phone"
          className="form-control"
          // style={{ fontSize: "18px", borderColor: "#0b406a" }}
          type="text"
          placeholder="Phone"
          ref={register({
            validate: value => validatePhoneNumber(value)
          })}
          inputMode="tel"
          mask="+1 (999) 999-9999"
        />
      </FormGroup>
      <FormGroup className="self-reservations__input" validationState={(!!errors.email && "error") || null}>
        <input
          className="form-control "
          placeholder="Email"
          name="email"
          ref={register({
            required: true,
            pattern: {
              value: /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,5}$/i
            }
          })}
        />
        {errors.email && errors.email.type === "pattern" && <HelpBlock>Please enter a valid email</HelpBlock>}
      </FormGroup>
      <FormGroup className="self-reservations__input" validationState={(!!errors.specialRequests && "error") || null}>
        <input
          className="form-control "
          ref={register({ required: false })}
          placeholder="Special Requests"
          name="specialRequests"
        />
      </FormGroup>
      <FormGroup className="self-reservations__input d-flex align-items-center">
        <input
          ref={register({ required: false })}
          id="reservationUpdatesCBox"
          className="checkbox"
          type="checkbox"
          name="reservationUpdates"
        />
        <label htmlFor="reservationUpdatesCBox" className="checkbox_description">
          Yes, I want text updates and reminders about my reservation.
        </label>
      </FormGroup>
      <FormGroup className="self-reservations__input d-flex align-items-center">
        <input
          id="promotionsCBox"
          ref={register({ required: false })}
          className="checkbox"
          type="checkbox"
          name="promotions"
        />
        <label htmlFor="promotionsCBox" className="checkbox_description">
          I would like to receive promotions from {selectedLocation.name}.
        </label>
      </FormGroup>
      <div className="self-reservations__button-wrp" style={{ paddingBottom: 20 }}>
        {loading ? (
          <div style={{ flex: 1, marginTop: 20 }}>
            <BeatLoader margin={2.5} size={20} color={buttonStyle.backgroundColor || "#5472D3"} />
          </div>
        ) : (
          <Button style={buttonStyle} type="submit">
            Confirm Reservation
          </Button>
        )}
      </div>
    </Form>
  );
};

export default DetailsStep;
