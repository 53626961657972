export function selectedVisitor(visits) {
  if (visits === undefined) {
    return;
  }
  for (let visit of visits) {
    if (visit.selected === true) {
      return visit;
    }
  }
}

export function selectedVisitorIndex(visits) {
  //if (visits === undefined) return;

  for (var i = 0; i < visits.length; i++) {
    if (visits[i].selected === true) {
      return i;
    }
  }
  return 0;
}

export function removeSelected(visits) {
  return visits.filter(v => {
    return v.selected !== true;
  });
}
