import React, { useEffect, useRef } from "react";
import GoogleMapReact from "google-map-react";
import { Wrapper } from "@googlemaps/react-wrapper";
import BeatLoader from "react-spinners/BeatLoader";
import LocationOn from "@material-ui/icons/LocationOn";

export const API_KEY = "AIzaSyCA15UnFEC81pSWKWTeiONweQ6bIIR26EQ";

const GoogleMap = ({
  location,
  loading,
  setLoading,
  isLocationValid,
  withLeftSeperator = false,
  reservationDetails
}) => {
  const Marker = options => {
    return <LocationOn style={{ fontSize: 40, fill: "#b73b00" }} />;
  };

  const [geo, setGeo] = React.useState({});

  const fetchGeo = async () => {
    if (location && isLocationValid) {
      setLoading(true);
      const address = `${location.settings.address.address1}, ${location.settings.address.city}, ${location.settings.address.state} ${location.settings.address.postal}`;
      try {
        const res = await fetch(`https://maps.googleapis.com/maps/api/geocode/json?address=${address}&key=${API_KEY}`);
        const json = await res.json();
        if (json.status === "OK") {
          setGeo(json.results[0].geometry.location);
          setLoading(false);
        }
      } catch (e) {
        console.error(e);
        setLoading(false);
      }
    }
  };

  function usePrevious(value) {
    const ref = useRef();
    useEffect(() => {
      ref.current = value;
    }, [value]);
    return ref.current;
  }

  const prevLocation = usePrevious(location);
  React.useEffect(() => {
    if (location && location.id !== prevLocation?.id) {
      fetchGeo();
    }
    //eslint-disable-next-line react-hooks/exhaustive-deps
  }, [location]);

  return loading ? (
    <>
      {withLeftSeperator && <div className="self-reservations__separate-line" />}
      <div style={{ flex: 1, marginTop: 50 }}>
        <BeatLoader margin={2.5} size={20} color={"#5472D3"} />
      </div>
    </>
  ) : (
    location && isLocationValid && (
      <>
        {withLeftSeperator && <div className="self-reservations__separate-line" />}
        <div className="self-reservations__address">
          <div className="self-reservations__title">
            {location.name} - {location.settings.address.city}
          </div>
          <div className="self-reservations__title address small">
            {location.settings.address.address1}, {location.settings.address.city}, {location.settings.address.state}
          </div>
          {reservationDetails}
          <div className="self-reservations__map">
            <Wrapper apiKey={API_KEY}>
              <GoogleMapReact bootstrapURLKeys={{ key: API_KEY }} defaultCenter={geo} defaultZoom={15}>
                <Marker {...geo} />
              </GoogleMapReact>
            </Wrapper>
          </div>
        </div>
      </>
    )
  );
};

export default GoogleMap;
