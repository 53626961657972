import { routes } from "../api/routes";

export async function createPendingVisit(businessId, data) {
  const payload = { ...data };
  return fetch(`${routes.createPendingVisit}?id=${businessId}`, {
    method: "POST",
    body: JSON.stringify(payload),
    headers: {
      Accepts: "application/json"
    }
  })
    .then(response => {
      if (!response.ok || (response.status > 200 && response.status < 600)) {
        throw new Error(response.statusText);
      }
      return response;
    })
    .catch(error => {
      console.error("error triggering createPendingVisit function", error);
      throw error;
    });
}

export async function verifyPendingVisitCode(data) {
  return fetch(`${routes.verifyPendingVisitCode}`, {
    method: "POST",
    body: JSON.stringify(data),
    headers: {
      Accepts: "application/json"
    }
  })
    .then(response => {
      if (!response.ok || (response.status > 200 && response.status < 600)) {
        throw new Error(response.statusText);
      }
      return response;
    })
    .catch(error => {
      console.error("error triggering verifyPendingVisitCode function", error);
      throw error;
    });
}
