export const calculateLogoStyle = appearance => {
  var result = {};
  if (!appearance) {
    return result;
  }
  if (appearance.headerLogoMaxHeight) {
    result.maxHeight = appearance.headerLogoMaxHeight;
  }
  if (appearance.headerLogoMaxWidth) {
    result.maxWidth = appearance.headerLogoMaxWidth;
  }
  return result;
};
