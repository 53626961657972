import { parsePhoneNumberFromString } from "libphonenumber-js";

const formatPhone = phone => {
  if (phone && phone !== "") {
    if (isOnlyMaskPresent(phone)) {
      return "";
    }

    return phone.replace(/[-_()\s]/gi, "").slice(0, 12);
  }
  return undefined;
};

const isOnlyMaskPresent = phone => {
  return phone === "+1 (___) ___-____";
};

const validatePhoneNumber = value => {
  if (!value || isOnlyMaskPresent(value)) {
    return false;
  }

  let phoneValue = formatPhone(value);
  const phone = parsePhoneNumberFromString(phoneValue || "");
  return !!phone && phone.isValid() && (phone.country === "US" || phone.country === "CA");
};

export { validatePhoneNumber, formatPhone };
