import React, { Component } from "react";

import classname from "classnames";

class Waitlist extends Component {
  getName(name, selected) {
    if (!selected) {
      return name + "...";
    }
    return name;
  }
  render() {
    if (this.props.waitlist.visits) {
      return (
        <div className="waitList">
          <table
            style={{
              background: this.props.appearance.tableBackgroundColor,
              borderWidth: this.props.appearance.tableBorderWidth,
              borderColor: this.props.appearance.tableBorderColor,
              borderStyle: "solid"
            }}
          >
            <thead>
              <tr>
                <th
                  className={classname("partyName", "waitListHeader")}
                  style={{
                    fontFamily: this.props.appearance.fontFamily,
                    color: this.props.appearance.tableFontColor
                  }}
                >
                  #
                </th>
                <th
                  className={classname("partyName", "waitListHeader")}
                  style={{
                    fontFamily: this.props.appearance.fontFamily,
                    color: this.props.appearance.tableFontColor
                  }}
                >
                  Name
                </th>
                <th
                  className={classname("partySize", "waitListHeader")}
                  style={{
                    fontFamily: this.props.appearance.fontFamily,
                    color: this.props.appearance.tableFontColor
                  }}
                >
                  Party Size
                </th>
              </tr>
            </thead>
            <tbody>
              {this.props.waitlist.visits.map((visit, index) => {
                var sizeCl = classname("partySize");
                return (
                  <tr key={index}>
                    <td
                      style={{
                        fontFamily: this.props.appearance.fontFamily,
                        color: visit.selected
                          ? this.props.appearance.highlightFontColor
                          : this.props.appearance.tableFontColor,
                        background: visit.selected ? this.props.appearance.highlightBackgroundColor : "transparent"
                      }}
                    >
                      {index + 1}
                    </td>
                    <td
                      style={{
                        fontFamily: this.props.appearance.fontFamily,
                        color: visit.selected
                          ? this.props.appearance.highlightFontColor
                          : this.props.appearance.tableFontColor,
                        background: visit.selected ? this.props.appearance.highlightBackgroundColor : "transparent"
                      }}
                    >
                      {this.getName(visit.name, visit.selected)}
                    </td>
                    <td
                      style={{
                        fontFamily: this.props.appearance.fontFamily,
                        color: visit.selected
                          ? this.props.appearance.highlightFontColor
                          : this.props.appearance.tableFontColor,
                        background: visit.selected ? this.props.appearance.highlightBackgroundColor : "transparent"
                      }}
                      className={sizeCl}
                    >
                      {visit.partySize}
                    </td>
                  </tr>
                );
              })}
            </tbody>
          </table>
        </div>
      );
    } else {
      return <br />;
    }
  }
}

export default Waitlist;
