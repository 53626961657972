import React from "react";
import Footer from "../../Footer";
import Header from "../../Header";

function LayoutWrapper({ business, appearance, children }) {
  return (
    <div className="app" style={{ background: appearance.pageBackgroundColor }}>
      <Header business={business} appearance={appearance} fontColor={appearance.fontColor}></Header>
      <div className="content">{children}</div>
      <Footer appearance={appearance} />
    </div>
  );
}

export default LayoutWrapper;
