import React, { useEffect, useState } from "react";
import { Form, FormGroup, Button } from "react-bootstrap";
import { useForm } from "react-hook-form";
import BeatLoader from "react-spinners/BeatLoader";
import { logVirtualPageView } from "../../../util/analytics";
import {
  createReservationWithPendingStatus,
  editReservation,
  getResourcesByBusinessId,
  getSelfBookingAvailableSlots
} from "../../../services/reservation.service";
import { DateTime, Interval, Settings } from "luxon";
import { useNavigate } from "react-router-dom";
import { sessionStorageManager, SSItem } from "../../../util/sessionStorageUtil";
import { useSelector } from "react-redux";
import classNames from "classnames";
const defaultPartySizes = [
  {
    value: 1,
    label: "1 person"
  },
  {
    value: 2,
    label: "2 people"
  },
  {
    value: 3,
    label: "3 people"
  },
  {
    value: 4,
    label: "4 people"
  },
  {
    value: 5,
    label: "5 people"
  },
  {
    value: 6,
    label: "6 people"
  },
  {
    value: 7,
    label: "7 people"
  },
  {
    value: 8,
    label: "8 people"
  }
];

const parsePartySizesFromNumber = ({ min, max }: { min?: number; max: number }) => {
  if (min) {
    const partySizes = Array.from(Array(Number(max) - Number(min) + 1).keys()).map(i => {
      const size = i + Number(min);
      return {
        label: `${size} ${size === 1 ? "person" : "people"}`,
        value: size
      };
    });

    return partySizes;
  }
  const n = max;
  const N = Number(n);
  if (!N || n < N) {
    return [];
  }

  const partySizes = [
    {
      value: 1,
      label: "1 person"
    }
  ];

  if (N === 1) {
    return partySizes;
  }

  for (let i = 2; i <= N; i++) {
    partySizes.push({
      value: i,
      label: i + " people"
    });
  }
  return partySizes;
};

const SearchSlotStep = ({
  selectedLocation,
  buttonStyle,
  goToNextStep,
  isMobile,
  accountId,
  timezone = "America/New_York",
  error,
  setError,
  business,
  reservationId,
  selection
}) => {
  Settings.defaultZone = timezone;
  const navigate = useNavigate();

  const { register, handleSubmit, errors } = useForm();

  const [times, setTimes] = useState<any[]>([]);
  const [shouldInputsBeUpdated, setShouldInputsBeUpdated] = useState(false);
  const { display } = useSelector<
    any,
    {
      display: {
        widgetMode?: boolean;
        date?: string;
        time?: string;
        partySize?: string;
        prepopulate?: boolean;
        title?: string;
      };
    }
  >(state => state);
  const [slots, setSlots] = useState<any>(null);
  const [slotsLoading, setSlotsLoading] = useState(false);
  const selected =
    DateTime.fromISO(business?.settings?.minDate).toFormat("yyyy-MM-dd") || DateTime.now().toFormat("yyyy-MM-dd");
  const minDate = DateTime.fromISO(business?.settings?.minDate);
  const maxDate = DateTime.fromISO(business?.settings?.maxDate);
  const [selectedDate, setSelectedDate] = useState(selected);
  const [selectedTime, setSelectedTime] = useState<string | null>(null);
  const [selectedPartySize, setSelectedPartySize] = useState(1);
  const [buttonDisabled, setButtonDisabled] = useState(false);
  const [partySizes, setPartySizes] = useState(defaultPartySizes);
  const [minPartySize] = useState(business?.settings?.minPartySize || 1);

  const fetchResourcesMaxPartySize = async id => {
    const res = await getResourcesByBusinessId(id);
    if (res?.success) {
      const maxPartySize = Math.max(...res.data.map(d => d.maxPartySize || 0));
      const pSizes = parsePartySizesFromNumber({ min: minPartySize, max: maxPartySize });
      setPartySizes(pSizes);
      if (pSizes.some(p => p.value === selection?.selectedPartySize)) {
        setSelectedPartySize(selection?.selectedPartySize);
      } else if (!pSizes.some(p => p.value === selectedPartySize) || selectedPartySize < minPartySize) {
        setSelectedPartySize(minPartySize);
      }
    }
    setSlotsLoading(false);
  };

  useEffect(() => {
    if (selectedLocation && shouldInputsBeUpdated) {
      fetchResourcesMaxPartySize(selectedLocation.id);
    }
  }, [selectedLocation, shouldInputsBeUpdated]);

  useEffect(() => {
    if (selectedLocation?.settings?.reservations?.hours && selectedDate && shouldInputsBeUpdated) {
      const weekNumberDT = DateTime.fromISO(selectedDate).weekday;
      const weekNumber = weekNumberDT === 7 ? 0 : weekNumberDT;

      const { businessStart, businessEnd } = getStartAndEndTimes(business, selectedDate, weekNumber);

      const timeValues = Interval.fromDateTimes(businessStart, businessEnd)
        .splitBy({ minutes: 15 })
        .map(intrvl => intrvl.start)
        .map(time => ({ value: time!.toFormat("HH:mm"), label: time!.toFormat("h:mm a") }));
      if (!timeValues.length) {
        setSelectedDate(
          DateTime.fromFormat(selectedDate, "yyyy-MM-dd")
            .plus({ hours: 24 })
            .toFormat("yyyy-MM-dd")
        );
        return;
      }

      setTimes(timeValues);
      const existingTime = timeValues.find(t => t.value === selectedTime);
      if (!existingTime) {
        setSelectedTime(timeValues[0].value);
      }
    }
  }, [selectedLocation, selectedDate, shouldInputsBeUpdated]);

  useEffect(() => {
    if (selection && reservationId && shouldInputsBeUpdated) {
      setSelectedDate(selection.selectedDate);
      setSelectedTime(selection.selectedTime);
      setSelectedPartySize(selection.selectedPartySize);
    }
  }, [selection, reservationId]);

  useEffect(() => {
    if (display?.prepopulate && display?.date && display?.time && display?.partySize) {
      setShouldInputsBeUpdated(false);
      setSelectedDate(display.date);
      setSelectedTime(display.time as any);
      setSelectedPartySize(Number(display.partySize) as any);
      setPartySizes(parsePartySizesFromNumber({ max: Number(display.partySize) }));
      const time = DateTime.fromFormat(display.time, "HH:mm");
      setTimes([
        {
          value: time.toFormat("HH:mm"),
          label: time.toFormat("h:mm a")
        }
      ]);
      onSubmit({
        date: display.date,
        time: display.time,
        partySize: display.partySize
      });
    } else {
      setShouldInputsBeUpdated(true);
    }
  }, [display]);

  const getStartAndEndTimes = (business, selectedDate, weekNumber) => {
    const selectedIsMin = DateTime.fromISO(selectedDate).hasSame(minDate, "day");
    const selectedIsMax = DateTime.fromISO(selectedDate).hasSame(maxDate, "day");
    let begin;
    let shift;
    let end;
    if (selectedIsMin) {
      begin = DateTime.now().set({
        millisecond: minDate.millisecond,
        second: minDate.second,
        minute: minDate.minute,
        hour: minDate.hour
      });
    } else {
      shift = findShift(business, selectedDate);
      if (shift) {
        begin = DateTime.fromFormat(shift.begin, "HH:mm");
      } else {
        begin = DateTime.fromFormat(selectedLocation.settings.reservations.hours[weekNumber]?.begin, "HH:mm");
      }
    }
    if (selectedIsMax) {
      end = DateTime.now().set({
        millisecond: maxDate.millisecond,
        second: maxDate.second,
        minute: maxDate.minute,
        hour: maxDate.hour
      });
    } else {
      shift = !shift ? findShift(business, selectedDate) : shift;
      if (shift) {
        end = DateTime.fromFormat(shift.end, "HH:mm");
      } else {
        end = DateTime.fromFormat(selectedLocation.settings.reservations.hours[weekNumber]?.end, "HH:mm");
      }
    }

    return { businessStart: begin, businessEnd: end };
  };

  const findShift = (business, selectedDate) => {
    let dt = DateTime.fromISO(selectedDate)
      .setZone(business.settings.timezone)
      .startOf("day");
    for (let s of business.shifts) {
      const shiftDate = DateTime.fromISO(s.date)
        .setZone(business.settings.timezone)
        .startOf("day");
      if (shiftDate.diff(dt).milliseconds === 0) {
        return s;
      }
    }
  };

  const handleDateChanged = e => {
    setSelectedDate(e.target.value);
    setSlots(null);
    setError("");
  };

  const handleTimeChanged = e => {
    setSelectedTime(e.target.value);
    setSlots(null);
    setError("");
  };

  const handlePartySizeChanged = e => {
    setSelectedPartySize(e.target.value);
    setSlots(null);
    if (e.target.value < business?.settings?.minPartySize) {
      setError(`We only take reservations for parties of ${business?.settings?.minPartySize} or more.`);
      setButtonDisabled(true);
    } else {
      setError("");
      setButtonDisabled(false);
    }
  };

  const onSubmit = async data => {
    setSlotsLoading(true);
    setError("");
    setSlots(null);
    logVirtualPageView("Waitly - Available Times");
    if (minPartySize > data.partySize) {
      setError(`We only take reservations for parties of ${minPartySize} or more.`);
      setSlotsLoading(false);
      return;
    }
    const res = await getSelfBookingAvailableSlots({ ...data, reservationId }, selectedLocation);
    if (res.success) {
      setSlots(res.data);
      if (!res.data.some(slot => slot.isAvailable)) {
        setError(
          `Sorry, there are no available reservations within one hour of ${DateTime.fromFormat(
            data.time,
            "HH:mm"
          ).toFormat("hh:mm a")}`
        );
      }
    } else {
      setError(res.error);
    }
    setShouldInputsBeUpdated(true);
    setSlotsLoading(false);
  };

  const handleSlotClick = async slotTime => {
    setSlotsLoading(true);
    const h24FormatTime = DateTime.fromFormat(slotTime, "hh:mm").toFormat("HH:mm");
    setSelectedTime(h24FormatTime);
    const data = {
      date: selectedDate,
      time: h24FormatTime,
      partySize: selectedPartySize,
      accountId,
      businessId: selectedLocation.id,
      timezone
    };

    if (reservationId) {
      // edit reservation
      try {
        const res = await editReservation(reservationId, data);
        if (!res?.success) throw new Error(res?.error || res?.meesage || res);

        sessionStorageManager(SSItem.RESERVATION_INFO).set({ ...data, ...res.data });
        logVirtualPageView("Waitly - Reservation Details");
        navigate(`?step=confirm-edit&id=${reservationId}`);
      } catch (e) {
        setError((e as any).message || "Something went wrong");
        setSlots(null);
      }
    } else {
      try {
        const res = await createReservationWithPendingStatus(data);
        if (!res?.success) throw new Error(res?.error || res?.meesage || res);

        sessionStorageManager(SSItem.RESERVATION_INFO).set({ ...data, ...res.data });
        logVirtualPageView("Waitly - Reservation Details");
        navigate(`?step=details`);
      } catch (e) {
        setError((e as any).message || "Something went wrong");
        setSlots(null);
      }
    }

    setSlotsLoading(false);
  };

  return (
    <Form className="self-reservations__form" noValidate onSubmit={handleSubmit(onSubmit)}>
      {
        <div className="self-reservations__title">
          {display?.title ? display.title : reservationId ? "Change a Reservation" : "Make a Reservation"}
        </div>
      }
      {shouldInputsBeUpdated && (
        <>
          <div className="self-reservations__input  mb-2 d-flex">
            <FormGroup className="self-reservations__input  mb-0" validationState={(!!errors.date && "error") || null}>
              <input
                className="form-control "
                ref={register({ required: true })}
                placeholder="Select an option"
                name="date"
                type="date"
                min={minDate.toISODate() as string}
                max={maxDate.toISODate() as string}
                onChange={handleDateChanged}
                value={selectedDate || ""}
                style={{ width: 200 }}
              />
            </FormGroup>
            <div style={{ width: 20 }} />
            <FormGroup className="self-reservations__input  mb-0" validationState={(!!errors.time && "error") || null}>
              <select
                className="form-control "
                ref={register({ required: true })}
                name="time"
                onChange={handleTimeChanged}
                value={selectedTime || ""}
              >
                {times.map((time, index) => (
                  <option key={"t" + index} value={time.value}>
                    {time.label}
                  </option>
                ))}
              </select>
            </FormGroup>
          </div>
          <FormGroup className="self-reservations__input" validationState={(!!errors.partySize && "error") || null}>
            <select
              className={classNames({
                "form-control": true,
                hidden: !(partySizes?.length > 1)
              })}
              ref={register({ required: true })}
              name="partySize"
              onChange={handlePartySizeChanged}
              value={selectedPartySize || ""}
            >
              {partySizes.map((pz, index) => (
                <option key={"pz" + index} value={pz.value}>
                  {pz.label}
                </option>
              ))}
            </select>
          </FormGroup>

          <div className="self-reservations__button-wrp">
            <Button style={buttonStyle} className="findATimeButton" disabled={buttonDisabled} type="submit">
              Find a time
            </Button>
          </div>
        </>
      )}
      {error && <div className="self-reservations__error-text">{error}</div>}
      <div className="self-reservations__slots-wrp">
        {!![...(slots || [])]?.filter(s => s.isAvailable)?.length && !slotsLoading ? (
          slots.map((slot, idx) => {
            return (
              <Button
                key={"slot" + idx}
                disabled={!slot.isAvailable}
                style={{ ...buttonStyle, fontSize: 14 }}
                type="button"
                onClick={() => handleSlotClick(slot.time)}
              >
                {DateTime.fromFormat(slot.time, "HH:mm")
                  .toFormat("h:mm a")
                  .toLowerCase()}
              </Button>
            );
          })
        ) : slotsLoading ? (
          <div style={{ flex: 1, marginTop: 20 }}>
            <BeatLoader margin={2.5} size={20} color={buttonStyle.backgroundColor || "#5472D3"} />
          </div>
        ) : null}
      </div>
    </Form>
  );
};

export default SearchSlotStep;
