// this file was made in TS and compile to JS
var ISSItem;
(function(ISSItem) {
  ISSItem["RESERVATION_INFO"] = "reservationInfo";
  ISSItem["TIMER"] = "timer";
})(ISSItem || (ISSItem = {}));
export const SSItem = {
  RESERVATION_INFO: ISSItem.RESERVATION_INFO,
  TIMER: ISSItem.TIMER
};
export const sessionStorageManager = item => {
  return {
    get: () => {
      return JSON.parse(sessionStorage.getItem(item));
    },
    set: data => {
      sessionStorage.setItem(item, JSON.stringify(data));
    },
    remove: () => {
      sessionStorage.removeItem(item);
    }
  };
};
