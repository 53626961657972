export const defaultAppearance = {
  publicWaitlist: {
    mainBackgroundColor: "#FFFFFF",
    fontFamily: "'Lato', sans-serif",
    headerLogo: "",
    headerTextColor: "#FFFFFF",
    headerBackgroundColor: "#6180cd",
    headerText: "Your Company",
    sidebarBackgroundColor: "#3b3238",
    sidebarTextColor: "#FFFFFF",
    listItemColor: "#323232",
    footerBackgroundColor: "#6180cd"
  },
  tableBorderColor: "#4d6cc1",
  tableBorderWidth: "2px",
  addGuestBackground: "#fff",
  addGuestBusinessNameColor: "#000",
  tableBackgroundColor: "#fff",
  tableFontColor: "#333",
  pageBackgroundColor: "#FFFFFF",
  highlightBackgroundColor: "#002bb7",
  highlightFontColor: "#FFF",
  logoUrl: "",
  fontFamily: "Poppins, Verdana, Helvetica, sans-serif",
  fontColor: "#333",
  footerText: "",
  footerBackgroundColor: "#FFFFFF",
  footerTextColor: "",
  hideNames: true,
  logoMarginTop: "10px",
  headerLogoMaxHeight: "100px"
};

export const DEFAUL_TIMEZONE = "America/New_York";

export const DEFAULT_RESERVATION_DURATION = 30;
export const DEFAULT_HOURS = [
  {
    begin: "00:00",
    end: "00:00"
  },
  {
    begin: "00:00",
    end: "00:00"
  },
  {
    begin: "00:00",
    end: "00:00"
  },
  {
    begin: "00:00",
    end: "00:00"
  },
  {
    begin: "00:00",
    end: "00:00"
  },
  {
    begin: "00:00",
    end: "00:00"
  },
  {
    begin: "00:00",
    end: "00:00"
  }
];

export const VisitStatus = {
  reserved: 0,
  waiting: 1,
  notified: 2,
  seated: 3,
  cancelled: 7,
  confirmedCancel: 8,
  walkout: 9,
  deleted: 10,
  pending: 11
};
