import React, { Component } from "react";

import { selectedVisitor, selectedVisitorIndex } from "../model/waitlistFunctions";

class WaitStatus extends Component {
  getMessage(visit, visits) {
    if (visit.hideEstimate) {
      let i = selectedVisitorIndex(visits);
      if (i === 0) {
        return `We will be with you as soon as we can. Thank you for your patience.`;
      } else if (i === 1) {
        return `We will be with you shortly. Thank you for your patience.`;
      } else {
        return `There are ${i} parties ahead of you. We will be with you as soon as we can.`;
      }
    } else if (isNaN(visit.remainingWait) || visit.remainingWait === undefined || visit.remainingWait <= 0) {
      return "We should be ready for you any minute now.";
    } else {
      return `Estimated wait: ${visit.remainingWait} minutes`;
    }
  }

  render() {
    var message = "You are no longer on the waitlist. Thank you and we'll see you next time";
    if (this.props.waitlist.visits !== undefined && this.props.waitlist.visits.length > 0) {
      var visit = selectedVisitor(this.props.waitlist.visits);
      if (visit) {
        message = this.getMessage(visit, this.props.waitlist.visits);
      }
    }
    return (
      <div>
        <div
          style={{
            fontFamily: this.props.appearance.fontFamily,
            color: this.props.appearance.fontColor,
            fontSize: "18px"
          }}
          className={"lightText"}
        >
          {message}
        </div>
      </div>
    );
  }
}

export default WaitStatus;
