import React from "react";
import image from "../imgs/PoweredByBlue.svg";
import ReactLoading from "react-loading";
import { useSelector } from "react-redux";

function Loading() {
  const { display } = useSelector(state => state || { header: true });
  return (
    <div className="loading">
      {display.header && (
        <a href="https://waitlyapp.com">
          <img src={image} alt="Waitly Logo" />
        </a>
      )}
      <ReactLoading type="bubbles" color="#002bb7" />
    </div>
  );
}

export default Loading;
