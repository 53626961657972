import React from "react";
import Logo from "../../ui/Logo";

const GlobalWailistHeader = ({
  appearance: { headerLogo, headerTextColor, fontFamily, headerBackgroundColor },
  name
}) => {
  return (
    <div style={{ background: headerBackgroundColor }} className="global-waitlist-header-wrapper">
      {headerLogo && <Logo imageSrc={headerLogo} alt={"company-logo"} className={"waitlist-logo"} />}
      {name && (
        <div
          style={{
            fontFamily: fontFamily,
            color: headerTextColor
          }}
          className="global-waitlist-header-title"
        >
          {`Welcome to ${name}`}
        </div>
      )}
    </div>
  );
};

export default GlobalWailistHeader;