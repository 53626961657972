import React, { useEffect, useState } from "react";
import Loading from "../Loading";
import "../GlobalWaitlist/GlobalWaitlist.scss";
import { defaultAppearance } from "../../constants";
import GlobalWaitlistHeader from "../GlobalWaitlist/GlobalWaitlistHeader";
import GlobalWaitlistSidebar from "../GlobalWaitlist/GlobalWaitlistSidebar";
import GlobalWaitlistFooter from "../GlobalWaitlist/GlobalWaitlistFooter";
import GlobalWaitlistListRevision from "./GlobalWaitlistListRevision";
import { useLocation } from "react-router-dom";
import { routes } from "../../api/routes";
import { logError } from "../../services/log.service";

const GlobalWaitlistRevision = () => {
  let urlParams = new URLSearchParams(useLocation().search);
  const [loading, setLoading] = useState(true);
  const [businessId] = useState(urlParams.get("id"));
  const [business, setBusiness] = useState(null);
  const [visits, setVisits] = useState([]);
  const [waitTimes, setWaitTimes] = useState([]);
  const [appearance, setAppearance] = useState(null);

  useEffect(() => {
    fetchAppearance(businessId);
    fetchWaitlist(businessId);
    //eslint-disable-next-line react-hooks/exhaustive-deps
  }, [businessId]);

  const fetchWaitlist = businessId => {
    setLoading(true);
    fetch(`${routes.getPublicWaitlist}?id=${businessId}`)
      .then(response => response.json())
      .then(json => {
        setVisits(onSetVisits(json.waitTimes, json.visits));
        setWaitTimes(json.waitTimes);
      })
      .catch(error => {
        console.log("err: ", error);
        logError("TV Screen", error, { businessId });
        // set default appearance
        setLoading(false);
      });
  };

  const onSetVisits = (waitTimes, visits) => {
    let sortedVisits = { ...waitTimes };
    for (const key in sortedVisits) {
      sortedVisits[key] = [];
    }
    for (let visit of visits) {
      if (visit.service) {
        sortedVisits[visit.service].push(visit);
      }
    }
    return sortedVisits;
  };

  const fetchAppearance = businessId => {
    fetch(`${routes.checkInData}?id=${businessId}`)
      .then(response => response.json())
      .then(json => {
        setAppearance({ ...defaultAppearance.publicWaitlist, ...(json.appearance.publicWaitlist || {}) });
        setLoading(false);
        setBusiness(json.businesses[0]);
      })
      .catch(err => {
        console.log("err: ", err);
        logError("TV Screen", err, { businessId });
        // set default appearance
        setAppearance(defaultAppearance);
        setLoading(false);
      });
  };

  return loading ? (
    <>
      <Loading />
    </>
  ) : (
    <div className="global-waitlist-wrapper" style={{ backgroundColor: appearance.mainBackgroundColor }}>
      <GlobalWaitlistHeader name={business ? business.name : ""} appearance={appearance} />
      <div className="global-waitlist-body-wrapper">
        <GlobalWaitlistSidebar waitTimes={waitTimes} appearance={appearance} />
        <GlobalWaitlistListRevision visits={visits} categories={waitTimes} appearance={appearance} />
      </div>
      <GlobalWaitlistFooter appearance={appearance} />
    </div>
  );
};

export default GlobalWaitlistRevision;
