import React, { useState, useEffect } from "react";
import CheckCircleIcon from "@material-ui/icons/CheckCircle";
import { Button } from "react-bootstrap";
import { useSelector } from "react-redux";
import classNames from "classnames";

const ConfirmStep = ({ reservationDetails, appearance, isChangeConfirm = false }) => {
  var buttonStyle = {
    fontFamily: appearance.fontFamily,
    color: appearance.highlightFontColor,
    backgroundColor: appearance.highlightBackgroundColor,
    border: "0px",
    marginBottom: "20px"
  };
  const [isWidgetMode, setIsWidgetMode] = useState(false);

  const { display } = useSelector(state => {
    return state;
  });

  useEffect(() => {
    if (display && display.widgetMode !== undefined && display.widgetMode === true) {
      setIsWidgetMode(true);
    }
  }, [display]);

  function handleButtonClick() {
    if (window.parent) {
      window.parent.postMessage({ source: "waitly", command: "dismiss" }, "*");
    }
  }

  return (
    <div className="self-reservations__form">
      <div
        className={classNames({
          "self-reservations__title mt-1": true,
          "mx-4": isWidgetMode
        })}
      >
        {isChangeConfirm
          ? "Thank you you're all set. Your reservation has been updated."
          : "Thank you, you're all set! You will receive a confirmation shortly."}
      </div>
      <div className="add-guest__success-icon">
        <CheckCircleIcon className="add-guest__icon"></CheckCircleIcon>
      </div>
      {reservationDetails}
      {isWidgetMode && (
        <div>
          <Button style={buttonStyle} onClick={handleButtonClick} type="button">
            OK
          </Button>
        </div>
      )}
    </div>
  );
};

export default ConfirmStep;
