export const SET_WAITLIST = "SET_WAITLIST";
export const CANCEL_VISIT = "CANCEL_VISIT";
export const SET_DISPLAY = "SET_DISPLAY";

export function setWailist(waitlist) {
  return {
    type: SET_WAITLIST,
    waitlist
  };
}

export function cancelVisit() {
  return {
    type: CANCEL_VISIT
  };
}

export function setDisplay(display) {
  return {
    type: SET_DISPLAY,
    display
  };
}
