import React, { useEffect } from "react";
import ReactDOM from "react-dom/client";
import App from "./components/App";
import NotFound from "./components/NotFound";
import { Provider } from "react-redux";
import { createStore } from "redux";
import rootReducer from "./reducers";
import "./index.css";
import "./css/light-bootstrap-dashboard.css";
import AddGuest from "./components/AddGuest/AddGuest";
import Page from "./components/Page";
import { BrowserRouter, Route, Routes } from "react-router-dom";
import AddReservation from "./components/AddReservation/AddReservation";
import GlobalWaitlist from "./components/GlobalWaitlist";
import GlobalWaitlistRevision from "./components/GlobalWaitlistRevision";
import "./css/shared.scss";
import MainWrapper from "./components/MainWrapper/MainWrapper";

const store = createStore(rootReducer);
const root = ReactDOM.createRoot(document.getElementById("root"));

root.render(
  <Provider store={store}>
    <BrowserRouter>
      <MainWrapper>
        <Routes>
          <Route path="/wl/:id" element={<Page title="Waitly - Waitlist" element={<App />} />} />
          <Route path="/:id" element={<Page title="Waitly - Waitlist" element={<App />} />} />
          <Route path="/" element={<Page title="Waitly - Waitlist" element={<NotFound />} />} />
          <Route
            path="/waitlist2"
            element={<Page title="Waitly - Wait Times" element={<GlobalWaitlist type="waitlist" />} />}
          />
          <Route
            path="/waitlist"
            element={<Page title="Waitly - Wait Times" element={<GlobalWaitlistRevision type="waitlist" />} />}
          />
          <Route
            path="/r/:shortId/success"
            element={<Page title="Trulieve - Book Success" element={<AddReservation type="success" />} />}
          />
          <Route
            path="/r/:shortId/success/*"
            element={<Page title="Trulieve - Book Success" element={<AddReservation type="success" />} />}
          />
          <Route
            path="/r/:shortId/waitlist"
            element={<Page title="Trulieve - Waitlist" element={<AddReservation type="waitlist" />} />}
          />
          <Route
            path="/r/:shortId/edit"
            element={<Page title="Trulieve - Edit" element={<AddReservation type="edit" />} />}
          />
          <Route
            path="/r/:shortId/edit/*"
            element={<Page title="Trulieve - Edit" element={<AddReservation type="edit" />} />}
          />
          <Route path="/r/:shortId" element={<Page title="Trulieve - Check in" element={<AddReservation />} />} />
          <Route path="/r/:shortId/*" element={<Page title="Trulieve - Check in" element={<AddReservation />} />} />
          <Route
            path="/checkin/:id/:catId"
            element={<Page title="Waitly - Self Check-in" element={<AddGuest type="checkin" />} />}
          />
          <Route
            path="/select/:id/book"
            element={
              <Page title="Waitly - Select Location " element={<AddGuest type="select-location" isBooking={true} />} />
            }
          />
          <Route
            path="/select/:id"
            element={<Page title="Waitly - Select Location" element={<AddGuest type="select-location" />} />}
          />
          <Route
            path="/book/:id"
            element={<Page title="Waitly - Find a time" element={<AddGuest type="book" isBooking={true} />} />}
          />
          <Route
            path="/checkin/:id"
            element={<Page title="Waitly - Self Check-in" element={<AddGuest type="checkin" />} />}
          />
          <Route
            path="/checkin-confirm/:id"
            element={<Page title="Waitly - Confirm check-in" element={<AddGuest type="checkin-confirm" />} />}
          />
          <Route
            path="/checkin-successful/:id"
            element={<Page title="Waitly - Check-in Successful" element={<AddGuest type="checkin-successful" />} />}
          />
          <Route
            path="/checkin-exists-error/:id"
            element={<Page title="Waitly - Check-in Error" element={<AddGuest type="checkin-exists-error" />} />}
          />
        </Routes>
      </MainWrapper>
    </BrowserRouter>
  </Provider>
);
