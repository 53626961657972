import React from "react";

const GlobalWaitlistList = ({ visits, appearance: { listItemColor } }) => {
  return (
    <div className="global-waitlist-list-wrapper">
      {visits && visits.length > 0 ? (
        visits.map((visit, index) => (
          <div key={index} className="gloal-waitlist-list-unit">
            <div
              style={{
                color: listItemColor
              }}
              className="gloal-waitlist-list-unit-number"
            >
              {index + 1}
            </div>
            <div
              style={{
                color: listItemColor
              }}
              className="gloal-waitlist-list-unit-name"
            >
              {visit.name}
            </div>
            {visit.service && (
              <div
                style={{
                  color: listItemColor,
                  border: `2px solid ${listItemColor}`
                }}
                className="gloal-waitlist-list-unit-label"
              >
                {visit.service}
              </div>
            )}
          </div>
        ))
      ) : (
        <div className="global-waitlist-empty">Waitlist is currently empty</div>
      )}
    </div>
  );
};

export default GlobalWaitlistList;
