import React, { Component } from "react";
import Waitlist from "./Waitlist";
import Footer from "./Footer";
import Loading from "./Loading";
import { connect } from "react-redux";
import { setWailist, cancelVisit } from "../actions";
import WaitStatus from "./WaitStatus";
import CancelButton from "./CancelButton";
import { selectedVisitor } from "../model/waitlistFunctions";
import { defaultAppearance } from "../constants";
import { routes } from "../api/routes";

class App extends Component {
  constructor() {
    super();
    const path = window.location.pathname;
    const id = path.substr(path.lastIndexOf("/") + 1);
    this.state = { loading: true, hash: id };
  }

  componentDidMount() {
    this.fetchWaitlist(this.state.hash);
  }

  setAppearance(appearance) {
    this.setState({ appearance });
  }

  fetchWaitlist(id) {
    fetch(`${routes.getWaitlist}?id=${id}`)
      .then(response => response.json())
      .then(json => {
        this.setAppearance({ ...defaultAppearance, ...(json.appearance || {}) });
        this.setState({ loading: false, hash: id });
        this.props.setWailist(json);
      })
      .catch(err => {
        //no longer on waitlist
        this.setAppearance(defaultAppearance);
        this.setState({ loading: false });
        console.log(err);
      });
  }

  calculateLogoStyle() {
    var result = {};
    if (!this.state.appearance) {
      return result;
    }
    if (this.state.appearance.headerLogoMaxHeight) {
      result.maxHeight = this.state.appearance.headerLogoMaxHeight;
    }
    if (this.state.appearance.headerLogoMaxWidth) {
      result.maxWidth = this.state.appearance.headerLogoMaxWidth;
    }
    return result;
  }

  render() {
    if (this.props.waitlist.visits && this.props.waitlist.visits.length > 0) {
      this.visit = selectedVisitor(this.props.waitlist.visits);
    }
    if (this.state.loading) {
      return (
        <div>
          <Loading />
        </div>
      );
    } else {
      return (
        <div className="app" style={{ background: this.state.appearance.pageBackgroundColor }}>
          {this.state.appearance.logoUrl ? (
            <div className="header-logo" style={{ marginTop: this.state.appearance.logoMarginTop }}>
              <img
                className="header-logo-image"
                style={this.calculateLogoStyle()}
                src={this.state.appearance.logoUrl}
                alt="header logo"
              />
            </div>
          ) : null}
          <div className="header">
            {!this.state.appearance.logoUrl ? (
              <h2
                style={{
                  fontFamily: this.state.appearance.fontFamily,
                  color: this.state.appearance.fontColor
                }}
              >
                {this.props.waitlist.business}
              </h2>
            ) : null}

            <WaitStatus appearance={this.state.appearance} waitlist={this.props.waitlist} />
          </div>
          {this.visit ? (
            <div className="content">
              <Waitlist appearance={this.state.appearance} waitlist={this.props.waitlist} />
              <CancelButton appearance={this.state.appearance} waitlist={this.props.waitlist} />
            </div>
          ) : null}

          <Footer appearance={this.state.appearance} />
          <ins
            className="adsbygoogle"
            style={{ display: "block" }}
            data-ad-client="ca-pub-1158103590652242"
            data-ad-slot="5167012412"
            data-ad-format="auto"
            data-full-width-responsive="true"
          ></ins>
          <script>(adsbygoogle = window.adsbygoogle || []).push({});</script>
        </div>
      );
    }
  }
}

function mapStateToProps(state) {
  return state;
}

export default connect(mapStateToProps, { setWailist, cancelVisit })(App);
