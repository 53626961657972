import React from "react";
import CheckCircleIcon from "@material-ui/icons/CheckCircle";
import "./AddReservation.scss";

function ReservationAcknowledgment(props) {
  return (
    <div className="reservation-acknowledgment">
      <div className="reservation-acknowledgment__title">
        {props.type === "waitlist"
          ? `Thank you! You're on the list`
          : `Thank you for making a reservation. Please arrive a few minutes early to check in`}
      </div>
      <CheckCircleIcon
        style={{ color: props.appearance.highlightBackgroundColor || "green" }}
        className="add-guest__icon"
      ></CheckCircleIcon>
    </div>
  );
}

export default ReservationAcknowledgment;
