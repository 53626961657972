import { routes } from "../api/routes";
import { formatPhone } from "../util/phoneUtils";

export async function bookReservation(shortId, data) {
  let body = data;
  if (body.phone) {
    body.phone = formatPhone(body.phone);
  }
  return fetch(`${routes.bookReservation}?id=${shortId}`, {
    method: "POST",
    body: JSON.stringify(body),
    headers: {
      Accepts: "application/json"
    }
  })
    .then(response => {
      if (!response.ok || (response.status > 200 && response.status < 600)) {
        throw new Error(response.statusText);
      }
      return response;
    })
    .catch(error => {
      console.error("error triggering bookReservation function", error);
      throw error;
    });
}

export async function putToWaitlist(shortId, { category, phone }) {
  let body = {};
  if (phone) {
    body.phone = formatPhone(phone);
  }
  if (category) {
    body.category = category;
  }
  return fetch(`${routes.putToWaitlist}?id=${shortId}`, {
    method: "POST",
    body: JSON.stringify(body),
    headers: {
      Accepts: "application/json"
    }
  })
    .then(response => {
      if (!response.ok || (response.status > 200 && response.status < 600)) {
        throw new Error(response.statusText);
      }
      return response;
    })
    .catch(error => {
      console.error("error triggering putToWaitlist function", error);
      throw error;
    });
}

export async function getSelfBookingAvailableSlots({ date, time, partySize, reservationId = null }, business) {
  return fetch(
    `${routes.getSelfBookingAvailableSlots}?date=${date}&time=${time}&partySize=${partySize}&businessId=${
      business?.id
    }${reservationId ? `&reservationId=${reservationId}` : ""}`,
    {
      method: "GET",
      headers: {
        Accepts: "application/json"
      }
    }
  )
    .then(response => {
      if (!response.ok || (response.status > 200 && response.status < 600)) {
        return new Error("Something went wrong");
      }
      return response.json();
    })
    .catch(error => {
      console.error("error triggering getSelfBookingAvailableSlots function", error);
      throw error;
    });
}

export const createReservationWithPendingStatus = async data => {
  return fetch(`${routes.createReservationWithPendingStatus}`, {
    method: "POST",
    body: JSON.stringify(data),
    headers: {
      Accepts: "application/json"
    }
  })
    .then(async response => {
      if (!response.ok || (response.status > 201 && response.status < 600)) {
        const res = await response.json();
        return new Error(res?.error ?? "Something went wrong");
      }
      return response.json();
    })
    .catch(error => {
      console.log(error);
      console.error("error triggering createReservationWithPendingStatus function", error);
      throw error;
    });
};

export const editReservation = async (id, data) => {
  return fetch(`${routes.editReservation}?id=${id}`, {
    method: "POST",
    body: JSON.stringify(data),
    headers: {
      Accepts: "application/json"
    }
  })
    .then(async response => {
      if (!response.ok || (response.status > 201 && response.status < 600)) {
        const res = await response.json();
        return new Error(res?.error ?? "Something went wrong");
      }
      return response.json();
    })
    .catch(error => {
      console.log(error);
      console.error("error triggering editReservation function", error);
      throw error;
    });
};

export async function getReservationById(id) {
  return fetch(`${routes.getReservationById}?reservationId=${id}`, {
    method: "GET",
    headers: {
      Accepts: "application/json"
    }
  })
    .then(async response => {
      if (!response.ok || (response.status > 200 && response.status < 600)) {
        const res = await response.json();
        return new Error(res?.error ?? "Something went wrong");
      }
      return response.json();
    })
    .catch(error => {
      console.error("error triggering getReservationById function", error);
      throw error;
    });
}

export async function confirmPendingReservation(data) {
  return fetch(`${routes.confirmPendingReservation}`, {
    method: "POST",
    body: JSON.stringify(data),
    headers: {
      Accepts: "application/json"
    }
  })
    .then(async response => {
      if (!response.ok || (response.status > 200 && response.status < 600)) {
        const res = await response.json();
        return new Error(res?.error ?? "Something went wrong");
      }
      return response.json();
    })
    .catch(error => {
      console.error("error triggering confirmPendingReservation function", error);
      throw error;
    });
}

export async function getResourcesByBusinessId(id) {
  return fetch(`${routes.getResourcesByBusinessId}?businessId=${id}`, {
    method: "GET",
    headers: {
      Accepts: "application/json"
    }
  })
    .then(async response => {
      if (!response.ok || (response.status > 200 && response.status < 600)) {
        const res = await response.json();
        return new Error(res?.error ?? "Something went wrong");
      }
      return response.json();
    })
    .catch(error => {
      console.error("error triggering getResourcesByBusinessId function", error);
      throw error;
    });
}

export async function cancelReservation(id) {
  return fetch(`${routes.cancelReservation}?id=${id}`, {
    method: "POST",
    headers: {
      Accepts: "application/json"
    }
  })
    .then(async response => {
      if (!response.ok || (response.status > 200 && response.status < 600)) {
        const res = await response.json();
        return new Error(res?.error ?? "Something went wrong");
      }
      return response.json();
    })
    .catch(error => {
      console.error("error triggering cancelReservation function", error);
      throw error;
    });
}
