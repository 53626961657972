import CancelModal from "components/CancelModal";
import React from "react";
import { Button } from "react-bootstrap";
import { useNavigate } from "react-router-dom";
import { cancelReservation } from "services/reservation.service";

const EditStep = ({ appearance, reservationDetails, reservationId }) => {
  const navigate = useNavigate();

  const buttonStyle = {
    fontFamily: appearance.fontFamily,
    color: appearance.highlightFontColor,
    backgroundColor: appearance.highlightBackgroundColor,
    border: "0px",
    marginBottom: "20px",
    width: "100%",
    maxWidth: "300px"
  };

  const [showCancelDialog, setShowCancelDialog] = React.useState(false);
  const [isLoadingCancel, setIsLoadingCancel] = React.useState(false);

  const handleCancelYes = async () => {
    setIsLoadingCancel(true);
    await cancelReservation(reservationId)
      .then(() => {
        handleCancelNo();
        navigate(`?step=cancellation-confirm`);
      })
      .finally(() => {
        setIsLoadingCancel(false);
      });
  };
  const handleCancelNo = () => {
    setShowCancelDialog(false);
  };

  return (
    <>
      <CancelModal
        show={showCancelDialog}
        onYes={() => handleCancelYes()}
        isYesLoading={isLoadingCancel}
        onNo={() => handleCancelNo()}
      />
      <div className="self-reservations__form">
        <div className={`self-reservations__title mt-1`}>CHANGE YOUR RESERVATION</div>
        <Button style={buttonStyle} onClick={() => navigate(`?id=${reservationId}`)} type="button">
          Change Reservation
        </Button>
        <Button style={buttonStyle} onClick={() => setShowCancelDialog(true)} type="button">
          Cancel Reservation
        </Button>
        {reservationDetails}
      </div>
    </>
  );
};

export default EditStep;
