import React from "react";

function ReservationDivider(props) {
  return (
    <div className="reservation-divider">
      <div className="reservation-divider__desktop">
        <div className="reservation-divider__or">OR</div>
        <div className="reservation-divider__line" style={{ background: props.appearance.fontColor }}></div>
      </div>
      <div className="reservation-divider__mobile">
        <div className="reservation-divider__line" style={{ background: props.appearance.fontColor }}></div>
        <div className="reservation-divider__or">OR</div>
        <div className="reservation-divider__line" style={{ background: props.appearance.fontColor }}></div>
      </div>
    </div>
  );
}

export default ReservationDivider;
