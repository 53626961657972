import React from "react";

const CancellationConfirmStep = () => {
  return (
    <div className="self-reservations__form">
      <div className={`self-reservations__title mt-1`}>Your reservation has been cancelled.</div>
      <div className={`self-reservations__title mb-8`}>We will see you next time</div>
    </div>
  );
};

export default CancellationConfirmStep;
