import React, { useEffect, useState } from "react";
import Loading from "../Loading";
import "./GlobalWaitlist.scss";
import { defaultAppearance } from "../../constants";
import GlobalWaitlistHeader from "./GlobalWaitlistHeader";
import GlobalWaitlistSidebar from "./GlobalWaitlistSidebar";
import GlobalWaitlistList from "./GlobalWaitlistList";
import GlobalWaitlistFooter from "./GlobalWaitlistFooter";
import { useLocation } from "react-router-dom";
import { routes } from "../../api/routes";

const GlobalWaitlist = props => {
  let urlParams = new URLSearchParams(useLocation().search);
  const [loading, setLoading] = useState(true);
  const [businessId] = useState(urlParams.get("id"));
  const [business, setBusiness] = useState(null);
  const [visits, setVisits] = useState([]);
  const [waitTimes, setWaitTimes] = useState([]);
  const [appearance, setAppearance] = useState(null);

  useEffect(() => {
    fetchAppearance(businessId);
    fetchWaitlist(businessId);
  }, [businessId]);

  const fetchWaitlist = businessId => {
    setLoading(true);
    fetch(`${routes.getPublicWaitlist}?id=${businessId}`)
      .then(response => response.json())
      .then(json => {
        setVisits(json.visits);
        setWaitTimes(json.waitTimes);
      })
      .catch(error => {
        console.log("err: ", error);
        // set default appearance
        setLoading(false);
      });
  };

  const fetchAppearance = businessId => {
    fetch(`${routes.checkInData}?id=${businessId}`)
      .then(response => response.json())
      .then(json => {
        setAppearance({ ...defaultAppearance.publicWaitlist, ...(json.appearance?.publicWaitlist || {}) });
        setLoading(false);
        setBusiness(json.business[0]);
      })
      .catch(err => {
        console.log("err: ", err);
        // set default appearance
        setBusiness("restaurant");
        setAppearance(defaultAppearance.publicWaitlist);
        setLoading(false);
      });
  };

  return loading ? (
    <>
      <Loading />
    </>
  ) : (
    <div className="global-waitlist-wrapper" style={{ backgroundColor: appearance.mainBackgroundColor }}>
      <GlobalWaitlistHeader name={business?.name} appearance={appearance} />
      <div className="global-waitlist-body-wrapper">
        <GlobalWaitlistSidebar waitTimes={waitTimes} appearance={appearance} />
        <GlobalWaitlistList visits={visits} appearance={appearance} />
      </div>
      <GlobalWaitlistFooter appearance={appearance} />
    </div>
  );
};

export default GlobalWaitlist;
