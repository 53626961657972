import React, { useState, useEffect } from "react";
import { useSelector } from "react-redux";
import image from "../imgs/PoweredByBlue.svg";

function Footer(props) {
  const [showFooter, setShowFooter] = useState(true);
  const { display } = useSelector(state => {
    return state;
  });

  useEffect(() => {
    if (display && display.widgetMode !== undefined) {
      setShowFooter(!display.widgetMode);
    } else {
      setShowFooter(true);
    }
  }, [display]);

  return (
    <>
      {showFooter && (
        <div
          className="wl-footer"
          style={{ background: props.appearance.footerBackgroundColor || "white", position: "relative !important" }}
        >
          {props.appearance.footerText ? (
            <div
              className="custom-footer"
              style={{
                color: props.appearance.footerTextColor,
                fontFamily: props.appearance.fontFamily,
                fontSize: props.appearance.footerFontSize,
                background: props.appearance.footerBackgroundColor
              }}
            >
              {props.appearance.footerText}
            </div>
          ) : (
            <a href="https://www.waitly.com">
              <img src={image} alt="footer logo" className="wl-footer-logo" />
            </a>
          )}
        </div>
      )}
    </>
  );
}

export default Footer;
