import React, { useState, useEffect } from "react";
import { Row, Col, Button } from "react-bootstrap";
import "./AddGuests.scss";
import { useSelector } from "react-redux";
import CheckCircleIcon from "@material-ui/icons/CheckCircle";
import { useNavigate } from "react-router-dom";

function VerificationSuccessful(props) {
  function handleButtonClick() {
    if (isWidgetMode()) {
      if (window.parent) {
        window.parent.postMessage({ source: "waitly", command: "dismiss" }, "*");
      }
    } else {
      navigateToWaitlist();
    }
  }
  function navigateToWaitlist() {
    navigate(`/wl/${props.hash}`);
  }

  function isWidgetMode() {
    return display && display.widgetMode !== undefined && display.widgetMode === true;
  }
  const navigate = useNavigate();
  const [buttonTitle, setButtonTitle] = useState("View the waitlist");
  const { display } = useSelector(state => {
    return state;
  });

  useEffect(() => {
    if (display && display.widgetMode !== undefined && display.widgetMode === true) {
      setButtonTitle("OK");
    }
  }, [display]);

  var buttonStyle = {
    fontFamily: props.appearance.fontFamily,
    color: props.appearance.highlightFontColor,
    backgroundColor: props.appearance.highlightBackgroundColor,
    border: "0px"
  };

  return (
    <div className="add-guest-wrapper">
      <div className="add-guest">
        <Row>
          <Col className="add-guest__title" md={12}>
            <span style={{ fontSize: "18px" }}>You're on the list! We will text you when it is your turn.</span>
          </Col>
        </Row>
        <Row>
          <Col md={12}>
            <div className="add-guest__success-icon">
              <CheckCircleIcon className="add-guest__icon"></CheckCircleIcon>
            </div>
          </Col>
        </Row>
        <Row>
          <Col md={12}>
            <div className="add-guest__actions">
              <Button style={buttonStyle} onClick={handleButtonClick} type="button">
                {buttonTitle}
              </Button>
            </div>
          </Col>
        </Row>
      </div>
    </div>
  );
}

export default VerificationSuccessful;
