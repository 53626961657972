import { SET_WAITLIST, CANCEL_VISIT, SET_DISPLAY } from "../actions";
import { combineReducers } from "redux";

function waitlist(state = {}, action) {
  switch (action.type) {
    case SET_WAITLIST:
      return action.waitlist;
    case CANCEL_VISIT:
      return {
        business: state.business
      };
    default:
      return state;
  }
}

function display(state = {}, action) {
  switch (action.type) {
    case SET_DISPLAY:
      return action.display;
    default:
      return state;
  }
}

function visit(state = {}, action) {
  switch (action.type) {
    default:
      return state;
  }
}

export default combineReducers({ waitlist, visit, display });
