import React from "react";
import { Button } from "react-bootstrap";
import { useLocation, useNavigate } from "react-router-dom";
import ReservationAcknowledgment from "./ReservationAcknowledgment";

function ViewWaitlist(props) {
  const navigate = useNavigate();
  const location = useLocation();
  function viewTheWaitlist() {
    let params = new URLSearchParams(location.search);
    navigate(`/wl/${params.get("hashId")}`);
  }

  return (
    <div className="view-waitlist">
      <ReservationAcknowledgment appearance={props.appearance} type="waitlist"></ReservationAcknowledgment>
      <Button
        style={{ fontFamily: props.appearance.fontFamily, background: props.appearance.fontColor }}
        className="reservation-button reservation-button--margin"
        onClick={viewTheWaitlist}
      >
        View the Waitlist
      </Button>
    </div>
  );
}

export default ViewWaitlist;
