import React, { useEffect, useState } from "react";
import { useSearchParams } from "react-router-dom";
import { connect } from "react-redux";
import { setDisplay } from "../../actions";
import CloseIcon from "@material-ui/icons/Close";
import "./MainWrapper.scss";
import image from "../../imgs/waitly_white_logo.svg";

function MainWrapper({ children, setDisplay, widgetMode }) {
  let [searchParams] = useSearchParams();
  let [title, setTitle] = useState("");
  let [footerText, setFooterText] = useState("");

  useEffect(() => {
    window.addEventListener("message", handleMessage);
    return () => {
      window.removeEventListener("message");
    };
  }, []);

  const handleMessage = message => {
    if (message.data && message.data.source && message.data.source === "waitly") {
      if (message.data.command === "setTitle") {
        setTitle(message.data.title);
      }
      if (message.data.command === "setFooterText") {
        setFooterText(message.data.footerText);
      }
    }
  };

  const handleOverlayClick = () => {
    closeWindow();
  };

  const closeWindow = () => {
    if (window.parent) {
      window.parent.postMessage({ source: "waitly", command: "dismiss" }, "*");
    }
  };

  const handleModalClick = e => {
    e.stopPropagation();
  };

  useEffect(() => {
    const date = searchParams.get("date");
    const time = searchParams.get("time");
    const partySize = searchParams.get("partySize");
    const shouldPrepopulate = searchParams.get("prepopulate") === "true";
    const title = searchParams.get("title");

    setDisplay({
      widgetMode: searchParams.get("widget") === "true",
      date,
      time,
      partySize,
      prepopulate: shouldPrepopulate,
      title
    });
  }, [setDisplay]);

  return (
    <div className={widgetMode ? "widget-container" : "main-container"} onClick={handleOverlayClick}>
      <div className="modal-container" onClick={handleModalClick}>
        <button className="close-button" onClick={closeWindow}>
          <CloseIcon className="close-icon" />
        </button>
        <div className="widget-modal" onClick={handleModalClick}>
          <div className="widget-title">{title}</div>
          {children}

          <div className="widget-footer">
            {footerText}{" "}
            <a href="https://www.waitly.com" style={{ color: "#8199ea" }} target="new">
              Learn More
            </a>
            <div style={{ paddingTop: "10px" }}>
              <img style={{ height: 30 }} src={image} className="footer-logo" alt="Waitly Logo" />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

function mapStateToProps(state) {
  return {
    widgetMode: state.display.widgetMode,
    date: state.display.date,
    time: state.display.time,
    partySize: state.display.partySize
  };
}

export default connect(mapStateToProps, { setDisplay })(MainWrapper);
